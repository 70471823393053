// NotificacaoMixin

export default {
  methods: {
    notificacao (acao, msg = '', tempo = 3000, callbacks = null) {
      let notificacao
      switch (acao) {
        case 'enviando':
          notificacao = this.$q.notify({
            position: 'top',
            timeout: tempo,
            progress: true,
            message: msg || 'Estamos salvando as informações',
            icon: 'hourglass_top',
            color: 'secondary'
          })
          break
        case 'erro':
          notificacao = this.$q.notify({
            position: 'top',
            progress: true,
            timeout: (tempo === 30000 ? 10000 : tempo), // deixar no caso de erro mais rapido
            message: msg || 'Atenção ocorreu um erro não identificado',
            actions: callbacks,
            icon: 'highlight_off',
            html: true,
            color: 'negative'
          })
          break
        case 'salvo':
          notificacao = this.$q.notify({
            position: 'top',
            timeout: tempo,
            progress: true,
            message: msg || 'Salvo com sucesso',
            icon: 'check_circle_outline',
            color: 'positive'
          })
          break
        case 'aviso':
          notificacao = this.$q.notify({
            position: 'top',
            progress: true,
            icon: 'info',
            message: msg || 'Aguarde',
            color: 'warning'
          })
          break
        default:
          notificacao = this.$q.notify(acao)
          break
      }
      return notificacao
    }
  }
}
