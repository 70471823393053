import MixinCadastro from 'src/pages/Cadastro/MixinCadastro'

export default {
  name: 'ModalCadastro',
  mixins: [MixinCadastro],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    tipo: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      email: null,
      senha: null
    }
  },
  methods: {
    toggleModal () {
      this.$emit('input', !this.value)
    },
    validarSenha (senha = '') {
      if (!senha) senha = ''
      return senha.length < 1 || senha.length > 5
    }
  },
  computed: {
    tituloModal () {
      return this.tipo === 1 || this.tipo === 3 ? 'Mensagem Enviada' : 'Proposta enviada!'
    },
    descricaoModal () {
      return this.tipo === 1
        ? 'Recebemos os seus dados e entraremos em contato via Whatsapp. Então fique de olho, esse carro está a um passo da sua garagem.'
        : this.tipo === 3
          ? 'Recebemos os seus dados e entraremos em contato via Whatsapp.'
          : 'Recebemos as suas escolhas para o financiamento do carro. Agora pedimos que entre em seu painel de proposta e continue processo de compra.'
    }
  },
  watch: {
    'tipo' (agora) {
      this.form.tipo = agora
    }
  }
}
