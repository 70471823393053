// import { extend } from 'quasar'

export const putDados = (state, { recurso, payload }) => {
  state.dados[recurso] = payload
}
export const putPagination = (state, { recurso, payload }) => {
  state.pagination[recurso] = payload
}
export const putCampoPagination = (state, { recurso, campo, value }) => {
  state.pagination[recurso][campo] = value
}
export const putNovoFiltro = (state, { recurso, payload }) => {
  // state.filtros.push(payload) não deve usar assim
  let incluir = true
  state.filtros[recurso].map((elem) => {
    if (JSON.stringify(elem) === JSON.stringify(payload)) {
      incluir = false
    }
  })
  if (incluir) { state.filtros[recurso] = Object.values({ ...state.filtros[recurso], payload }) }
}

export const putRemoverFiltro = (state, { recurso, index }) => {
  state.filtros[recurso].splice(index, 1)
}

export const limparFiltros = (state, recurso) => {
  state.filtros[recurso] = []
}

export const putDialogAuxiliar = (state, { mostrar, dadosExtras }) => {
  if (mostrar) state.dialogAuxiliar.mostrar = mostrar
  if (dadosExtras) state.dialogAuxiliar.dadosExtras = dadosExtras
}

export const putDialogAuxiliarMostrar = (state, mostrar) => {
  state.dialogAuxiliar.mostrar = mostrar
}

// export const putBusca = (state) => {
//   return state.busca ? state.busca : []
// }

// export const putFormLimpo = (state, payload) => {
//   state.formLimpo = payload
// }
// export const putApagarRegistro = (state, id) => {
//   state.dados = state.dados.filter((elem, index, arr) => elem.id !== id)
// }

// export const putCarregando = (state, payload) => {
//   state.paginationCarregando = payload
// }
// export const putForm = (state, payload) => {
//   if (!state.formLimpo) {
//     state.formLimpo = extend({}, state.form)
//   }
//   state.form = extend(state.form, payload)
// }

// export const putCampoForm = (state, { campo, value }) => {
//   state.form[campo] = value
// }

// export const putCampoAtributoForm = (state, { campo, index, value }) => {
//   state.form[campo][index] = value
// }

// export const putCampoFormInfo = (state, { campo, value }) => {
//   state.formInfo[campo] = value
// }

// export const putListagem = (state, payload) => {
//   state.listagem = payload
// }

// export const putIndiceListagem = (state, { index, value }) => {
//   state.listagem[index] = value
// }

// export const putCampoAtributoListagem = (state, { campo, index, value }) => {
//   state.listagem[index][campo] = value
// }

// export const setSearchFixo = (state, payload) => {
//   state.dadosModel.searchFixo = payload
// }

// export const setSearchJoin = (state, payload) => {
//   state.dadosModel.searchJoin = payload
// }

// export const setInclude = (state, payload) => {
//   state.dadosModel.include = payload
// }

// // provisorio
// export const setColunasVisiveisGrid = (state, payload) => {
//   // console.log('setei')
//   state.colunasVisiveisGrid = payload
// }
// export const putCarregado = (state, payload) => {
//   // state.pagination = extend(state.pagination, payload)
//   // buga passando pro mesmo
//   console.log('oi', payload)
//   console.log('oi carr', state.carregado)
//   state.carregado = payload
//   console.log('oi carr', state.carregado)
// }
