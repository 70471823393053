import AnuncioDadosMixin from './AnuncioDadosMixin'
import CardTag from './CardTag.vue'
import { date } from 'quasar'

export default {
  name: 'VehicleCard',
  components: { CardTag },
  mixins: [AnuncioDadosMixin],
  props: {
    dados: Object,
    classe: {
      type: String,
      default: ''
    }
  },
  computed: {
    getTags () {
      const tags = []
      if (this.dados) {
        if (this.dados.situacao_id === 9 || this.dados.situacao_id === 20) {
          tags.push({ type: 'vendido', value: 'VENDIDO' })
          return tags
        }
        if (this.dados.km === 0) {
          tags.push({ type: 'zero', value: 'ZERO KM' })
        }
        if (this.recemChegado) {
          tags.push({ type: 'recem-chegado', value: 'RECÉM CHEGADO' })
        }

        if (this.dados.pronta_entrega) {
          tags.push({ type: 'pronta-entrega', value: 'GARANTIA FÁBRICA' })
        }
        if (this.dados.outlet) {
          tags.push({ type: 'outlet', value: 'OUTLET' })
        }
        if (this.dados.ipva_pago) {
          tags.push({ type: 'ipva-pago', value: 'IPVA PAGO' })
        }
        if (this.dados.aplicativo) {
          tags.push({ type: 'aplicativo', value: 'CARRO DE APP' })
        }
        if (this.dados.blackfriday) {
          tags.push({ type: 'blackfriday', value: 'BLACK FRIDAY' })
        }
        if (this.dados.cyberweek) {
          tags.push({ type: 'cyberweek', value: 'CYBER WEEK' })
        }
        if (this.dados.situacao_id === 6) {
          tags.push({ type: 'em-preparacao', value: 'EM PREPARAÇÃO' })
        }
        if (this.dados.valor_anterior_pf && this.dados.valor_anterior_pf < this.dados.valor_pf) {
          tags.push({ type: 'promocao', value: 'PROMOÇÃO' })
        }

        return tags
      }
    },
    recemChegado () {
      if (this.dados && this.dados.created_at) {
        const data = date.formatDate(this.dados.created_at, 'YYYY-MM-DD HH:mm:ss')
        const diff = date.getDateDiff(new Date(), data)
        return diff <= 5
      }
    },
    marcaModelo () {
      const marca = this.dados?.marca_objeto?.marca_nome
      const modelo = this.dados?.modelo_objeto?.modelo_nome

      return `${marca} ${modelo}`
    },
    nomeVeiculo () {
      const marca = this.dados?.marca_objeto?.marca_nome
      const modelo = this.dados?.modelo_objeto?.modelo_nome
      const versao = this.dados.versao_objeto ? this.dados.versao_objeto.versao_nome : ''

      return `${marca} ${modelo} ${versao}`
    },
    objetos () {
      const marca = this.dados.marca_objeto ? this.removerPontuacao(this.dados.marca_objeto.marca_nome) : 'marca'
      const modelo = this.dados.modelo_objeto ? this.removerPontuacao(this.dados.modelo_objeto.modelo_nome) : 'modelo'
      const versao = this.dados.versao_objeto ? this.removerPontuacao(this.dados.versao_objeto.versao_nome) : 'versao'
      const ano = this.dados.ano_modelo ? this.dados.ano_modelo : 'ano'
      return { marca, modelo, versao, ano }
    },
    valorParcela () {
      return this.dados ? (this.dados.valor_parcelas ?? null) : null
    },
    valorVeiculo () {
      return this.dados ? (this.dados.valor_venda_pf > 0 ? this.dados.valor_venda_pf : 0) : 0
    }
  },
  methods: {
    removerPontuacao (nome) {
      return nome ? nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^0-9a-zA-Z ]/g, '').replace(/ /g, '-') : ''
    },
    irRota (rota) {
      let routeData = this.$router.resolve(rota)
      window.location.href = routeData.href
    }
  },
  filters: {
    imagemOriginal (endereco) {
      if (typeof endereco === 'undefined' || endereco === null) {
        return ''
      }
      if (!endereco.includes('netcarros')) {
        return endereco
      }
      if (typeof endereco === 'string') {
        let img = endereco.split('.')
        const extensao = img.pop()
        let nome = img.join('.')
        nome = `${nome}_original`
        img = `${nome}.${extensao}`
        return img
      } else {
        return ''
      }
    }
  }
}
