export default {
  name: 'Banner',
  props: {
    dados: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    possuiEtiqueta () {
      return this.dados && this.dados.etiqueta ? this.dados.etiqueta : false
    }
  }
}
