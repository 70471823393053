export function atualizarUsuario (state, retornoApi) {
  if (retornoApi) {
    state.id = retornoApi.id
    state.nome = retornoApi.nome
    state.email = retornoApi.email
    state.token = retornoApi.token
    state.roles = retornoApi.roles
    state.foto = retornoApi.foto_url_completa
  } else {
    state.id = null
    state.nome = null
    state.email = null
    state.token = null
    state.roles = []
    state.foto = null
  }
}
