export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    tipo: {
      type: String,
      default: 'reagendar'
    },
    callbackAcao: {
      type: Function,
      default: () => {}
    },
    dados: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    icone () {
      switch (this.tipo) {
        case 'reagendar':
          return { svg: 'app:modal_reagendar', size: '80px' }
        case 'tarifa':
          return { svg: 'app:modal_reagendar', size: '80px' }
        case 'financiamento':
          return { svg: 'app:modal_financiamento', size: '80px' }
        case 'agendado':
          return { svg: 'app:modal_agendado', size: '200px' }
        case 'desmarcar':
          return { svg: 'app:modal_desmarcar', size: '100px' }
        case 'videochamada':
          return { svg: 'app:modal_agendado', size: '200px' }
        case 'sucesso':
          return { svg: 'app:modal_sucesso', size: '80px' }
        default:
          return { svg: 'app:modal_reagendar', size: '80px' }
      }
    },
    comportamento () {
      switch (this.tipo) {
        case 'reagendar':
          return 'escolha'
        case 'tarifa':
          return 'tarifa'
        case 'financiamento':
          return 'confirmacao'
        case 'agendado':
          return 'confirmacao'
        case 'desmarcar':
          return 'escolha'
        case 'videochamada':
          return 'confirmacao'
        case 'sucesso':
          return 'confirmacao'
        default:
          return 'confirmacao'
      }
    },
    textos () {
      switch (this.tipo) {
        case 'reagendar':
          return { titulo: 'Confirma que deseja reagendar?', descricao: 'Etiam ullamcorper posuere enim, eu consectetur sem volutpat et. Sed leo mauris, rutrum at leo a, suscipit elementum erat.' }
        case 'tarifa':
          return { titulo: `Tarifa adicional de R$${this.dados.valorTarifa}`, descricao: `O endereço preenchido está a mais de ${this.dados.km}km da nossa loja, portanto será cobrada uma tarifa adicional.` }
        case 'financiamento':
          return { titulo: 'Proposta de Financiamento enviada com sucesso!', descricao: 'Agora vamos aguardar a resposta final. Iremos entrar em contato via telefone e email, fique ligado! Nosso horário de financiamento é de segunda a sábado das 9h às 19h.' }
        case 'agendado':
          return { titulo: 'Seu Test Drive foi agendado!', descricao: 'Etiam ullamcorper posuere enim, eu consectetur sem volutpat et. Sed leo mauris, rutrum at leo a, suscipit elementum erat.' }
        case 'desmarcar':
          return { titulo: 'Você deseja desmarcar o agendamento?', descricao: '' }
        case 'videochamada':
          return { titulo: 'Sua videochamada foi agendada', descricao: '' }
        case 'sucesso':
          return { titulo: 'O compromisso foi desmarcado!', descricao: 'Etiam ullamcorper posuere enim, eu consectetur sem volutpat et. Sed leo mauris, rutrum at leo a, suscipit elementum erat.' }
        default:
          return 'confirmacao'
      }
    }
  },
  methods: {
    toggleModal () {
      this.$emit('input', !this.value)
    }
  }
}
