import { LocalStorage } from 'quasar'
import PadraoModel from 'src/core/model/PadraoModel.js'

export default class VeiculosEntradaModel extends PadraoModel {
  recurso = 'veiculos-entradas';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Veiculos Entradas';

  colunasGrid = []

  form = {
    id: null,
    usuario_id: null,
    placa: null,
    versao_id: null,
    ano_fabricacao: null,
    ano_modelo: null,
    combustivel_id: null,
    cambio_id: null,
    valor_maximo: 0,
    valor_minimo: 0,
    valor_mercado: 0,
    valor_calculo_planilha: 0,
    observacao: null,

    // Auxilar
    marca_id: null,
    marca_nome: null,
    modelo_id: null,
    valor_saldo_devedor: 0
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
  /**
   * Retorna a lista de veiculos entrada do usuario logado
   * @returns VeiculoEntradas
   */
  getMeusVeiculos () {
    return new Promise((resolve, reject) => {
      let logado = LocalStorage.getItem('logado')
      if (logado && logado.id) {
        this.include = 'versao.modelo,situacao'
        this.getListagem({ params: { search: `usuario_id:${logado.id}` } }).then(res => {
          resolve(res)
        }).catch(reject)
      } else {
        reject('Não é possível obter o usuario logado')
      }
    })
  }
}
