import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Vantagens',
  components: { VueSlickCarousel },
  props: {
    title: {
      type: String,
      default: 'Vantagens que você só encontra aqui'
    }
  },
  data: () => ({
    vantagens: [
      {
        img: 'icones/layout/wi-fi',
        nome: 'Totalmente online',
        descricao: 'O processo de comprar é totalmente online e nós ainda levamos o carro até você. Simples assim.'
      },
      {
        img: 'icones/layout/prize-icon',
        nome: '100 dias de garantia total',
        descricao: 'O seu carro sai com 1 ano de Garantia Gestauto. Tranquilidade extra para você. Confira em nossa página de garantia.',
        link: { name: 'garantia', description: 'Saiba mais' }
      },
      {
        img: 'icones/layout/screw-nut',
        nome: 'Revisão 360°',
        descricao: 'Verificamos mais de 250 itens para que você se preocupe apenas em curtir seu carro seminovo.'
      },
      {
        img: 'icones/layout/doc-icon',
        nome: 'Motor e câmbio garantidos',
        descricao: 'Além de 1 ano de Garantia Gestauto, você conta com 365 dias de cobertura para motor e câmbio.'
      },
      {
        img: 'icones/layout/steering-wheel',
        nome: '10 dias de Test Drive',
        descricao: 'Após a compra do seu carro, você recebe o benefício de um Test Drive de 10 dias ou 350 km. São 10 dias para conhecer seu novo carro e ter a certeza da sua escolha.'
      },
      {
        img: 'icones/layout/car-icon',
        nome: 'Ajudamos a cuidar do carro',
        descricao: 'Você recebe um relatório completo do veículo e até e-mails periódicos com dicas de conservação.'
      }
    ],
    carrosselCards: {
      // lazyLoad: 'ondemand',
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      rows: 2,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: false,
      responsive: [
        {
          breakpoint: 2400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1024,
          settings: {
            rows: 2,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 599,
          settings: {
            rows: 2,
            slidesToShow: 1.1,
            slidesToScroll: 1
          }
        }
      ]
    }
  })
}
