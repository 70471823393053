import { LocalStorage, SessionStorage, Notify } from 'quasar'
export function setLogado ({ commit, state }, retornoApi) {
  LocalStorage.set('logado', retornoApi)
  localStorage.setItem('tokenBasica', retornoApi.token)
  SessionStorage.set('logado', true)
  commit('atualizarUsuario', retornoApi)
}

export function logoutUsuario ({ commit, state }, retornoApi) {
  LocalStorage.clear()
  SessionStorage.clear()
  commit('atualizarUsuario', null)
}

export function checarLoginAdmin ({ commit, state }) {
  if (LocalStorage.has('logado')) {
    const usuario = LocalStorage.getItem('logado')
    localStorage.setItem('tokenBasica', usuario.token)
    commit('atualizarUsuario', usuario)
    if (state.id) {
      let token = localStorage.getItem('tokenBasica')
      if (!token) {
        Notify.create({
          message: 'Sessão expirada. Por favor, faça login novamente !',
          position: 'top',
          timeout: 3000,
          color: 'warning'
        })
        window.location.href = '/login-admin'
      }

      const roles = state.roles
      const possuiPermissao = roles.find(r => r.name === 'Administrador')
      if (!possuiPermissao) {
        Notify.create({
          message: 'Você não possui permissão para acessar esta página!',
          position: 'top',
          timeout: 3000,
          color: 'negative'
        })
        this.$router.push({ name: 'home' })
      }
    } else {
      Notify.create({
        message: 'Token expirado. Por favor, realize o login novamente !',
        position: 'top',
        timeout: 3000,
        color: 'negative'
      })
      this.$router.push({ name: 'login-admin' })
    }
  } else {
    Notify.create({
      message: 'Token expirado. Por favor, realize o login novamente !',
      position: 'top',
      timeout: 3000,
      color: 'negative'
    })
    this.$router.push({ name: 'login-admin' })
  }
}
export function checarLoginEmpresa ({ commit, state }) {
  if (LocalStorage.has('logado')) {
    const usuario = LocalStorage.getItem('logado')
    localStorage.setItem('tokenBasica', usuario.token)
    commit('atualizarUsuario', usuario)
    if (state.id) {
      let token = localStorage.getItem('tokenBasica')
      if (!token) {
        Notify.create({
          message: 'Sessão expirada. Por favor, faça login novamente !',
          position: 'top',
          timeout: 3000,
          color: 'warning'
        })
        window.location.href = '/login-empresa'
      }

      const roles = state.roles
      console.log(roles, 'roles')
      const possuiPermissao = roles.find(r => r.name === 'Diretor Loja')
      if (!possuiPermissao) {
        Notify.create({
          message: 'Você não possui permissão para acessar esta página!',
          position: 'top',
          timeout: 3000,
          color: 'negative'
        })
        this.$router.push({ name: 'home' })
      }
    } else {
      Notify.create({
        message: 'Token expirado. Por favor, realize o login novamente !',
        position: 'top',
        timeout: 3000,
        color: 'negative'
      })
      this.$router.push({ name: 'login-empresa' })
    }
  } else {
    Notify.create({
      message: 'Token expirado. Por favor, realize o login novamente !',
      position: 'top',
      timeout: 3000,
      color: 'negative'
    })
    this.$router.push({ name: 'login-empresa' })
  }
}

export function renovarUsuario ({ commit }) {
  if (LocalStorage.has('logado')) {
    const usuario = LocalStorage.getItem('logado')
    localStorage.setItem('tokenBasica', usuario.token)
    commit('atualizarUsuario', usuario)
  }
}
