import PadraoModel from 'src/core/model/PadraoModel.js'

export default class LogradouroCepModel extends PadraoModel {
  recurso = 'logradouros/cep/{cep}'
  microServico = 'cep'
  include = null
  searchFixo = null
  nome = 'Cep'

  colunasGrid = []
  form = {}

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
