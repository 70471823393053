import PadraoModel from 'src/core/model/PadraoModel.js'

export default class AnuncioModel extends PadraoModel {
  recurso = 'estoque';
  microServico = 'negociacao';
  include = 'situacao,bem';
  searchFixo = null;
  nome = 'Estoque';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', descending: true, sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'marca', label: 'Marca', field: 'versao.modelo.marca.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'versoes:versao_id.modelos:modelo_id.marcas:marca_id|marcas.nome' },
    { name: 'modelo', label: 'Modelo', field: 'versao.modelo.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'versoes:versao_id.modelos:modelo_id.marcas:marca_id|modelos.nome' },
    { name: 'placa', label: 'Placa', field: 'bem.placa', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'situacao', label: 'Situação', field: 'situacao.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    bem_id: null,
    pronta_entrega: 0,
    outlet: 0,
    ipva_pago: 0,
    aplicativo: 0,
    blackfriday: 0,
    cyberweek: 0,
    icarros_codigo: '',
    icarros_plano: 0,
    icarros_inclusao: null,
    icarros_alteracao: null,
    icarros_exclusao: null,
    webmotors_codigo: '',
    webmotors_plano: 0,
    webmotors_inclusao: null,
    webmotors_alteracao: null,
    webmotors_exclusao: null
  };

  constructor (tipoNegociacao) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (tipoNegociacao) {
      this.recurso = 'anuncios-tipos-negociacoes'
      this.include = ''
      this.form = {
        id: null,
        habilitar: false,
        salvando: false,
        anuncio_id: null,
        tipo_negociacao_id: null,
        porcentagem_minima_sobre_minimo: null,
        valor_minimo: 0,
        valor_venda: 0,
        valor_anterior: 0,
        valor_inicial_publico: 0,
        valor_incremento: null,
        divulgar_oferta: null,
        retirar_apos_venda: null,
        pronta_entrega: 0,
        outlet: 0,
        ipva_pago: 0,
        aplicativo: 0,
        blackfriday: 0,
        cyberweek: 0
      }
    }
  }
}
