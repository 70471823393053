import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CredereLeadModel extends PadraoModel {
  recurso = 'credere/lead';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'Credere Lead';

  colunasGrid = []

  form = {
    cpf_cnpj: null,
    name: null,
    email: null,
    cnh: false,
    birthdate: null,
    phone_number: null,
    mother_name: null,
    retrieve_gender: null,
    retrieve_occupation: null,
    retrieve_profession: null,
    monthly_income: null,
    address: {
      zip_code: null,
      street: null,
      district: null,
      number: null,
      complement: null,
      city: null,
      state: null,
      reference: null
    }
  };

  constructor (logado) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (logado) {
      this.recurso += `/usuario/${logado.id}`
    }
  }
}
