import { render, staticRenderFns } from "./VehicleCard.vue?vue&type=template&id=46cf04a6&"
import script from "./VehicleCard.js?vue&type=script&lang=js&"
export * from "./VehicleCard.js?vue&type=script&lang=js&"
import style0 from "./VehicleCard.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QImg,QIcon,QTooltip,QBtn,QPagination});
