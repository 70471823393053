import PadraoModel from 'src/core/model/PadraoModel.js'

export default class SituacaoAnuncioModel extends PadraoModel {
  recurso = 'situacoes-anuncios';
  microServico = 'negociacao';
  include = '';
  searchFixo = null;
  nome = 'Situacoes';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', descending: true, sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' }
  ];

  form = {
    id: null,
    bem_id: null
  };

  constructor (tipoNegociacao) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (tipoNegociacao) {
      this.recurso = 'situacoes-anuncios'
      this.include = ''
      this.form = {
        id: null,
        nome: ''
      }
    }
  }
}
