import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import { openURL } from 'quasar'
import DpGridStore from 'src/store/dp-grid/'
// DpGridPageMixin // Pagina com grid padrao
export default {
  data () {
    return {
      id: null
    }
  },
  mixins: [NotificacaoMixin],
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },
  mounted () {
    // this.$store.dispatch('padrao/checkLogin', { caller: 'DpGridMixin' })
  },

  methods: {
    clickLinha ({ props, event, recurso }) {
      console.log('oi', props.row.naoENVIARFORM)
      if (event && (event.metaKey || event.altKey || event.ctrlKey)) {
        const routeData = this.$router.resolve({ name: (recurso + '.form'), params: { id: props.row.id, form: props.row } })
        openURL(routeData.href)
      } else {
        if (props.row.naoENVIARFORM) {
          this.$router.push({ name: (recurso + '.form'), params: { id: props.row.id } })
        } else {
          this.$router.push({ name: (recurso + '.form'), params: { id: props.row.id, form: props.row } })
        }
      }
    },
    clickApagarLinhaMix ({ props, model }) {
      // console.warn('modelxxx', model)
      this.$store.commit('dp-grid/putDialogAuxiliar', { mostrar: true, dadosExtras: { model, id: props.row.id } })
    }
  }
}
