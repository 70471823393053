export default {
  name: 'CardTag',
  props: [ 'value', 'type' ],
  data () {
    return {
      tag: {
        type: 'default',
        value: ''
      }
    }
  },
  computed: {
    tagType () {
      return this.tag.type
    },
    tagValue () {
      return this.tag.value
    }
  },
  methods: {
    setTag () {
      this.tag.type = this.type
      this.tag.value = this.value
    }
  },
  mounted () {
    this.setTag()
  }
}
