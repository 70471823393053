import PadraoModel from 'src/core/model/PadraoModel.js'

export default class LaudoModel extends PadraoModel {
  recurso = 'laudos';
  microServico = 'automotor';
  include = 'bem.versao.modelo.marca,bem.cor,situacao';
  searchFixo = null;
  nome = 'Laudos de Vistoria';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', descending: true, sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'marca', label: 'Marca', field: 'bem.versao.modelo.marca.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'bens:bem_id.versoes:versao_id.modelos:modelo_id.marcas:marca_id|marcas.nome' },
    { name: 'modelo', label: 'Modelo', field: 'bem.versao.modelo.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'bens:bem_id.versoes:versao_id.modelos:modelo_id.marcas:marca_id|modelos.nome' },
    { name: 'placa', label: 'Placa', field: 'placa', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'bens:bem_id|bens.placa' },
    { name: 'situacao', label: 'Situação', field: 'situacao.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    bem_id: null,
    situacao_id: 1,
    placa: ''
  };

  constructor (arquivos) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (arquivos) {
      this.recurso = 'laudos-arquivos'
      this.include = ''
    }
  }
}
