import PadraoModel from 'src/core/model/PadraoModel.js'

export default class ConsultaPlacaIcarrosModel extends PadraoModel {
  recurso = 'veiculo/consulta-icarros/';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Consulta Placa Icarros';

  colunasGrid = []

  form = {}

  constructor (placa) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (placa) this.recurso += placa
  }
}
