import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CorModel extends PadraoModel {
  recurso = 'cores';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Cores';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'ativo', label: 'Ativo', field: 'ativo', sortable: true, align: 'left', buscaTipo: 'boleanoSim' },
    { name: 'prioridade', label: 'Prioridade', field: 'prioridade', sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'created_at', label: 'Criado em', field: 'created_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' },
    { name: 'updated_at', label: 'Alterado em', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    nome: null,
    ativo: null,
    codigo_icarros: null,
    codigo_webmotors: null,
    codigo_usadosbr: null,
    codigo_olx: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
