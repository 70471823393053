import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CrederePropostaModel extends PadraoModel {
  recurso = 'credere/propostas';
  microServico = 'usuario';
  include = 'request_log,credere_proposta_tentativas,simulacao.anuncio,simulacao.usuario,veiculos_entradas,simulacao.request_log,anuncio,usuario,usuario.documento_lead,situacao_credere_proposta';
  searchFixo = null;
  nome = 'Propostas';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', descending: true, sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'situacao_credere_proposta', label: 'Situação', field: 'situacao_credere_proposta_id', align: 'left', buscaTipo: 'texto' },
    { name: 'cliente', label: 'Cliente', field: 'usuario.nome', align: 'left', buscaTipo: 'texto' },
    { name: 'cpf', label: 'CPF', field: 'usuario.documento', align: 'left', buscaTipo: 'texto' },
    { name: 'anuncio', label: 'Anuncio', field: 'anuncio', align: 'left', buscaTipo: 'texto' },
    { name: 'valor_financiamento', label: 'Valor do Financiamento', field: 'valor_financiamento', align: 'left', buscaTipo: 'texto' },
    { name: 'parcelamento', label: 'Parcelamento', field: 'parcelamento', align: 'left', buscaTipo: 'texto' },
    { name: 'credere_proposta_id', label: 'Credere Cód:', field: 'credere_proposta_id', align: 'left', buscaTipo: 'inteiro' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    banco_id: null,
    parcela: null,
    valorParcela: null,
    valorTotal: null,
    valor_financiado_credere: null,
    valor_extra_credere: null,
    usuario_id: null,
    veiculos_entradas_id: null,
    response_client_credere: null,
    valor_extras: null,
    simulacao_id: null,
    condition_id: null,
    valor_financiado_resposta: null,
    valor_total_resposta: null,
    valor_extras_resposta: null,
    valor_parcela_resposta: null,
    parcela_resposta: null
  };

  constructor (logado, status) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (logado) {
      this.recurso += `/usuario/${logado.id}`
    }
    if (status) {
      this.include = 'request_log,credere_proposta_tentativas,simulacao,veiculos_entradas,situacao_credere_proposta'
    }
  }
}
