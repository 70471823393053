import PadraoModel from 'src/core/model/PadraoModel.js'

export default class ModeloModel extends PadraoModel {
  recurso = 'modelos';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Modelos';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, descending: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'marca',
      label: 'Marca',
      field: 'marca.nome',
      descending: true,
      sortable: true,
      align: 'left',
      buscaTipo: 'texto',
      orderByCustomizado: 'marcas:marca_id|marcas.nome' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'uri', label: 'Uri', field: 'uri', sortable: false, align: 'left', buscaTipo: 'texto' },
    { name: 'acesso', label: 'Acessos', field: 'acesso', sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'created_at', label: 'Criado', field: 'created_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    marca_id: '',
    nome: null,
    uri: null,
    foto: null,
    foto_url_completa: null,
    prioridade: 1,
    ativo: 0,
    aprovado: 0,
    codigo_icarros: null,
    codigo_webmotors: null,
    codigo_usadosbr: null,
    codigo_olx: null,

    /* Campos Genericos (somente auxiliar) */
    categoria_id: null,
    marca: {
      categoria_id: null
    }
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
