import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'

export default {
  mixins: [NotificacaoMixin],
  data () {
    return {
      moneyFormatForComponent: {
        thousands: '.',
        decimal: ',',
        prefix: 'R$ ',
        precision: 2,
        masked: true
      },

      maskPorcetagem: {
        thousands: '',
        decimal: '.',
        prefix: '',
        precision: 2,
        masked: true
      }
    }
  }
}
