import PadraoModel from 'src/core/model/PadraoModel.js'

export default class UsuarioModel extends PadraoModel {
  recurso = 'usuarios'
  microServico = 'usuario'
  include = 'roles,situacao,usuario_locais_roles,documento_lead'
  searchFixo = null
  nome = 'Usuário'

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Id', field: 'id', sortable: true, align: 'left', descending: true, buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'celular', label: 'Celular', field: 'celular', sortable: true, align: 'left' },
    { name: 'documento', label: 'CPF', field: 'documento', sortable: true, align: 'left' },
    { name: 'situacao', label: 'Situação', field: 'situacao.nome', sortable: true, align: 'left' },
    { name: 'roles', label: 'Grupo', field: 'roles', sortable: true, align: 'left' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ]

  form = {
    id: null,
    nome: '',
    email: '',
    celular: '',
    documento: '',
    como_conheceu_id: null,
    facebook_id: null,
    google_id: null,
    apple_id: null,
    senha: null,
    senha_confirmation: null,
    situacao_id: 1,
    foto: null,
    foto_url_completa: null,
    token_push: null,
    roles: [],
    senha_por_email: 0,
    observacao_aprovacao: null,
    observacao_aprovacao_usuario_id: null,
    observacao_aprovacao_data: null,
    data_nascimento: null,
    pessoa_juridica: 0,
    genero: null,
    nome_fantasia: null,
    razao_social: null,
    qtd_funcionario: null,
    cep: '',
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    cidade: null,
    estado: null,
    ponto_referencia: null
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
