export default {
  name: 'Bancos',
  data () {
    return {
      bancoPrioridade: [
        {
          id: '341',
          name: 'Itaú Unibanco S.A.'
        },
        {
          id: '655',
          name: 'Banco Votorantim S.A.'
        },
        {
          id: '237',
          name: 'Banco Bradesco S.A.'
        },
        {
          id: '623',
          name: 'Banco Panamericano S.A'
        }
      ],
      bancoOptions: [
        {
          id: '394',
          name: 'Banco Bradesco Financiamentos S.A.'
        },
        {
          id: '74',
          name: 'Banco J. Safra S.A.'
        },
        {
          id: '623',
          name: 'Banco Panamericano S.A'
        },
        {
          id: '724',
          name: 'Banco Porto Seguro S.A.'
        },
        {
          id: '33',
          name: 'Banco Santander (Brasil) S. A.'
        },
        {
          id: '655',
          name: 'Banco Votorantim S.A.'
        },
        {
          id: '341',
          name: 'Itaú Unibanco S.A.'
        }
      ],
      bancoOptions1: [
        {
          id: '1',
          name: 'Banco do Brasil S.A.'
        },
        {
          id: '3',
          name: 'Banco da Amazônia S.A.'
        },
        {
          id: '4',
          name: 'Banco do Nordeste do Brasil S.A.'
        },
        {
          id: '7',
          name: 'Banco Nacional de Desenvolvimento Econômico e Social BNDES'
        },
        {
          id: '10',
          name: 'Credicoamo Crédito Rural Cooperativa'
        },
        {
          id: '11',
          name: 'Credit Suisse Hedging-Griffo Corretora de Valores S.A.'
        },
        {
          id: '12',
          name: 'Banco Inbursa S.A.'
        },
        {
          id: '14',
          name: 'Natixis Brasil S.A. Banco Múltiplo'
        },
        {
          id: '15',
          name: 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
        },
        {
          id: '16',
          name: 'Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul'
        },
        {
          id: '17',
          name: 'BNY Mellon Banco S.A.'
        },
        {
          id: '18',
          name: 'Banco Tricury S.A.'
        },
        {
          id: '21',
          name: 'Banestes S.A. Banco do Estado do Espírito Santo'
        },
        {
          id: '24',
          name: 'Banco Bandepe S.A.'
        },
        {
          id: '25',
          name: 'Banco Alfa S.A.'
        },
        {
          id: '29',
          name: 'Banco Itaú Consignado S.A.'
        },
        {
          id: '33',
          name: 'Banco Santander (Brasil) S. A.'
        },
        {
          id: '36',
          name: 'Banco Bradesco BBI S.A.'
        },
        {
          id: '37',
          name: 'Banco do Estado do Pará S.A.'
        },
        {
          id: '40',
          name: 'Banco Cargill S.A.'
        },
        {
          id: '41',
          name: 'Banco do Estado do Rio Grande do Sul S.A.'
        },
        {
          id: '47',
          name: 'Banco do Estado de Sergipe S.A.'
        },
        {
          id: '60',
          name: 'Confidence Corretora de Câmbio S.A.'
        },
        {
          id: '62',
          name: 'Hipercard Banco Múltiplo S.A.'
        },
        {
          id: '63',
          name: 'Banco Bradescard S.A.'
        },
        {
          id: '64',
          name: 'Goldman Sachs do Brasil  Banco Múltiplo S. A.'
        },
        {
          id: '65',
          name: 'Banco AndBank (Brasil) S.A.'
        },
        {
          id: '66',
          name: 'Banco Morgan Stanley S. A.'
        },
        {
          id: '69',
          name: 'Banco Crefisa S.A.'
        },
        {
          id: '70',
          name: 'Banco de Brasília S.A.'
        },
        {
          id: '74',
          name: 'Banco J. Safra S.A.'
        },
        {
          id: '75',
          name: 'Banco ABN Amro S.A.'
        },
        {
          id: '76',
          name: 'Banco KDB do Brasil S.A.'
        },
        {
          id: '77',
          name: 'Banco Inter S.A.'
        },
        {
          id: '78',
          name: 'Haitong Banco de Investimento do Brasil S.A.'
        },
        {
          id: '79',
          name: 'Banco Original do Agronegócio S.A.'
        },
        {
          id: '80',
          name: 'BT Corretora de Câmbio Ltda.'
        },
        {
          id: '81',
          name: 'BBN Banco Brasileiro de Negocios S.A.'
        },
        {
          id: '82',
          name: 'Banco Topazio S.A.'
        },
        {
          id: '83',
          name: 'Banco da China Brasil S.A.'
        },
        {
          id: '84',
          name: 'Uniprime Norte do Paraná - Cooperativa de Crédito Ltda.'
        },
        {
          id: '85',
          name: 'Cooperativa Central de Crédito Urbano - Cecred'
        },
        {
          id: '89',
          name: 'Cooperativa de Crédito Rural da Região da Mogiana'
        },
        {
          id: '91',
          name: 'Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred'
        },
        {
          id: '92',
          name: 'BRK S.A. Crédito, Financiamento e Investimento'
        },
        {
          id: '93',
          name: 'Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte'
        },
        {
          id: '94',
          name: 'Banco Finaxis S.A.'
        },
        {
          id: '95',
          name: 'Banco Confidence de Câmbio S.A.'
        },
        {
          id: '96',
          name: 'Banco BMFBovespa de Serviços de Liquidação e Custódia S/A'
        },
        {
          id: '97',
          name: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi'
        },
        {
          id: '98',
          name: 'Credialiança Cooperativa de Crédito Rural'
        },
        {
          id: '99',
          name: 'Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.'
        },
        {
          id: '100',
          name: 'Planner Corretora de Valores S.A.'
        },
        {
          id: '101',
          name: 'Renascença Distribuidora de Títulos e Valores Mobiliários Ltda.'
        },
        {
          id: '102',
          name: 'XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A.'
        },
        {
          id: '104',
          name: 'Caixa Econômica Federal'
        },
        {
          id: '105',
          name: 'Lecca Crédito, Financiamento e Investimento S/A'
        },
        {
          id: '107',
          name: 'Banco Bocom BBM S.A.'
        },
        {
          id: '108',
          name: 'PortoCred S.A. Crédito, Financiamento e Investimento'
        },
        {
          id: '111',
          name: 'Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
        },
        {
          id: '113',
          name: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios'
        },
        {
          id: '114',
          name: 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP'
        },
        {
          id: '117',
          name: 'Advanced Corretora de Câmbio Ltda.'
        },
        {
          id: '118',
          name: 'Standard Chartered Bank (Brasil) S.A. Banco de Investimento'
        },
        {
          id: '119',
          name: 'Banco Western Union do Brasil S.A.'
        },
        {
          id: '120',
          name: 'Banco Rodobens SA'
        },
        {
          id: '121',
          name: 'Banco Agibank S.A.'
        },
        {
          id: '122',
          name: 'Banco Bradesco BERJ S.A.'
        },
        {
          id: '124',
          name: 'Banco Woori Bank do Brasil S.A.'
        },
        {
          id: '125',
          name: 'Brasil Plural S.A. Banco Múltiplo'
        },
        {
          id: '126',
          name: 'BR Partners Banco de Investimento S.A.'
        },
        {
          id: '127',
          name: 'Codepe Corretora de Valores e Câmbio S.A.'
        },
        {
          id: '128',
          name: 'MS Bank S.A. Banco de Câmbio'
        },
        {
          id: '129',
          name: 'UBS Brasil Banco de Investimento S.A.'
        },
        {
          id: '130',
          name: 'Caruana S.A. Sociedade de Crédito, Financiamento e Investimento'
        },
        {
          id: '131',
          name: 'Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.'
        },
        {
          id: '132',
          name: 'ICBC do Brasil Banco Múltiplo S.A.'
        },
        {
          id: '133',
          name: 'Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar e'
        },
        {
          id: '134',
          name: 'BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda.'
        },
        {
          id: '135',
          name: 'Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
        },
        {
          id: '136',
          name: 'Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil'
        },
        {
          id: '137',
          name: 'Multimoney Corretora de Câmbio Ltda'
        },
        {
          id: '138',
          name: 'Get Money Corretora de Câmbio S.A.'
        },
        {
          id: '139',
          name: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo'
        },
        {
          id: '140',
          name: 'Easynvest - Título Corretora de Valores SA'
        },
        {
          id: '142',
          name: 'Broker Brasil Corretora de Câmbio Ltda.'
        },
        {
          id: '143',
          name: 'Treviso Corretora de Câmbio S.A.'
        },
        {
          id: '144',
          name: 'Bexs Banco de Câmbio S.A.'
        },
        {
          id: '145',
          name: 'Levycam - Corretora de Câmbio e Valores Ltda.'
        },
        {
          id: '146',
          name: 'Guitta Corretora de Câmbio Ltda.'
        },
        {
          id: '149',
          name: 'Facta Financeira S.A. - Crédito Financiamento e Investimento'
        },
        {
          id: '157',
          name: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.'
        },
        {
          id: '159',
          name: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor'
        },
        {
          id: '163',
          name: 'Commerzbank Brasil S.A. - Banco Múltiplo'
        },
        {
          id: '169',
          name: 'Banco Olé Bonsucesso Consignado S.A.'
        },
        {
          id: '172',
          name: 'Albatross Corretora de Câmbio e Valores S.A'
        },
        {
          id: '173',
          name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
        },
        {
          id: '174',
          name: 'Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento'
        },
        {
          id: '177',
          name: 'Guide Investimentos S.A. Corretora de Valores'
        },
        {
          id: '180',
          name: 'CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.'
        },
        {
          id: '182',
          name: 'Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento'
        },
        {
          id: '183',
          name: 'Socred S.A. - Sociedade de Crédito ao Microempreendedor'
        },
        {
          id: '184',
          name: 'Banco Itaú BBA S.A.'
        },
        {
          id: '188',
          name: 'Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores'
        },
        {
          id: '189',
          name: 'HS Financeira S/A Crédito, Financiamento e Investimentos'
        },
        {
          id: '190',
          name: 'Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio'
        },
        {
          id: '191',
          name: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.'
        },
        {
          id: '194',
          name: 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.'
        },
        {
          id: '196',
          name: 'Fair Corretora de Câmbio S.A.'
        },
        {
          id: '197',
          name: 'Stone Pagamentos S.A.'
        },
        {
          id: '204',
          name: 'Banco Bradesco Cartões S.A.'
        },
        {
          id: '208',
          name: 'Banco BTG Pactual S.A.'
        },
        {
          id: '212',
          name: 'Banco Original S.A.'
        },
        {
          id: '213',
          name: 'Banco Arbi S.A.'
        },
        {
          id: '217',
          name: 'Banco John Deere S.A.'
        },
        {
          id: '218',
          name: 'Banco BS2 S.A.'
        },
        {
          id: '222',
          name: 'Banco Credit Agrícole Brasil S.A.'
        },
        {
          id: '224',
          name: 'Banco Fibra S.A.'
        },
        {
          id: '233',
          name: 'Banco Cifra S.A.'
        },
        {
          id: '237',
          name: 'Banco Bradesco S.A.'
        },
        {
          id: '241',
          name: 'Banco Clássico S.A.'
        },
        {
          id: '243',
          name: 'Banco Máxima S.A.'
        },
        {
          id: '246',
          name: 'Banco ABC Brasil S.A.'
        },
        {
          id: '249',
          name: 'Banco Investcred Unibanco S.A.'
        },
        {
          id: '250',
          name: 'BCV - Banco de Crédito e Varejo S/A'
        },
        {
          id: '253',
          name: 'Bexs Corretora de Câmbio S/A'
        },
        {
          id: '254',
          name: 'Parana Banco S. A.'
        },
        {
          id: '260',
          name: 'Nu Pagamentos S.A.'
        },
        {
          id: '265',
          name: 'Banco Fator S.A.'
        },
        {
          id: '266',
          name: 'Banco Cédula S.A.'
        },
        {
          id: '268',
          name: 'Barigui Companhia Hipotecária'
        },
        {
          id: '269',
          name: 'HSBC Brasil S.A. Banco de Investimento'
        },
        {
          id: '271',
          name: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.'
        },
        {
          id: '300',
          name: 'Banco de la Nacion Argentina'
        },
        {
          id: '318',
          name: 'Banco BMG S.A.'
        },
        {
          id: '320',
          name: 'China Construction Bank (Brasil) Banco Múltiplo S/A'
        },
        {
          id: '341',
          name: 'Itaú Unibanco  S.A.'
        },
        {
          id: '366',
          name: 'Banco Société Générale Brasil S.A.'
        },
        {
          id: '370',
          name: 'Banco Mizuho do Brasil S.A.'
        },
        {
          id: '376',
          name: 'Banco J. P. Morgan S. A.'
        },
        {
          id: '389',
          name: 'Banco Mercantil do Brasil S.A.'
        },
        {
          id: '394',
          name: 'Banco Bradesco Financiamentos S.A.'
        },
        {
          id: '399',
          name: 'Kirton Bank S.A. - Banco Múltiplo'
        },
        {
          id: '412',
          name: 'Banco Capital S. A.'
        },
        {
          id: '422',
          name: 'Banco Safra S.A.'
        },
        {
          id: '456',
          name: 'Banco MUFG Brasil S.A.'
        },
        {
          id: '464',
          name: 'Banco Sumitomo Mitsui Brasileiro S.A.'
        },
        {
          id: '473',
          name: 'Banco Caixa Geral - Brasil S.A.'
        },
        {
          id: '477',
          name: 'Citibank N.A.'
        },
        {
          id: '479',
          name: 'Banco ItauBank S.A.'
        },
        {
          id: '487',
          name: 'Deutsche Bank S.A. - Banco Alemão'
        },
        {
          id: '488',
          name: 'JPMorgan Chase Bank, National Association'
        },
        {
          id: '492',
          name: 'ING Bank N.V.'
        },
        {
          id: '494',
          name: 'Banco de La Republica Oriental del Uruguay'
        },
        {
          id: '495',
          name: 'Banco de La Provincia de Buenos Aires'
        },
        {
          id: '505',
          name: 'Banco Credit Suisse (Brasil) S.A.'
        },
        {
          id: '545',
          name: 'Senso Corretora de Câmbio e Valores Mobiliários S.A.'
        },
        {
          id: '600',
          name: 'Banco Luso Brasileiro S.A.'
        },
        {
          id: '604',
          name: 'Banco Industrial do Brasil S.A.'
        },
        {
          id: '610',
          name: 'Banco VR S.A.'
        },
        {
          id: '611',
          name: 'Banco Paulista S.A.'
        },
        {
          id: '612',
          name: 'Banco Guanabara S.A.'
        },
        {
          id: '613',
          name: 'Omni Banco S.A.'
        },
        {
          id: '623',
          name: 'Banco Pan S.A.'
        },
        {
          id: '626',
          name: 'Banco Ficsa S. A.'
        },
        {
          id: '630',
          name: 'Banco Intercap S.A.'
        },
        {
          id: '633',
          name: 'Banco Rendimento S.A.'
        },
        {
          id: '634',
          name: 'Banco Triângulo S.A.'
        },
        {
          id: '637',
          name: 'Banco Sofisa S. A.'
        },
        {
          id: '641',
          name: 'Banco Alvorada S.A.'
        },
        {
          id: '643',
          name: 'Banco Pine S.A.'
        },
        {
          id: '652',
          name: 'Itaú Unibanco Holding S.A.'
        },
        {
          id: '653',
          name: 'Banco Indusval S. A.'
        },
        {
          id: '654',
          name: 'Banco A. J. Renner S.A.'
        },
        {
          id: '655',
          name: 'Banco Votorantim S.A.'
        },
        {
          id: '707',
          name: 'Banco Daycoval S.A.'
        },
        {
          id: '712',
          name: 'Banco Ourinvest S.A.'
        },
        {
          id: '719',
          name: 'Banif - Bco Internacional do Funchal (Brasil) S.A.'
        },
        {
          id: '735',
          name: 'Banco Neon S.A.'
        },
        {
          id: '739',
          name: 'Banco Cetelem S.A.'
        },
        {
          id: '741',
          name: 'Banco Ribeirão Preto S.A.'
        },
        {
          id: '743',
          name: 'Banco Semear S.A.'
        },
        {
          id: '745',
          name: 'Banco Citibank S.A.'
        },
        {
          id: '746',
          name: 'Banco Modal S.A.'
        },
        {
          id: '747',
          name: 'Banco Rabobank International Brasil S.A.'
        },
        {
          id: '748',
          name: 'Banco Cooperativo Sicredi S. A.'
        },
        {
          id: '751',
          name: 'Scotiabank Brasil S.A. Banco Múltiplo'
        },
        {
          id: '752',
          name: 'Banco BNP Paribas Brasil S.A.'
        },
        {
          id: '753',
          name: 'Novo Banco Continental S.A. - Banco Múltiplo'
        },
        {
          id: '754',
          name: 'Banco Sistema S.A.'
        },
        {
          id: '755',
          name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.'
        },
        {
          id: '756',
          name: 'Banco Cooperativo do Brasil S/A - Bancoob'
        },
        {
          id: '757',
          name: 'Banco Keb Hana do Brasil S. A.'
        }
      ]
    }
  }
}
