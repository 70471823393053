import PadraoModel from 'src/core/model/PadraoModel.js'

export default class LocalModel extends PadraoModel {
  recurso = 'locais'
  microServico = 'usuario'
  include = 'tipos_locais'
  searchFixo = null
  nome = 'Lojas'

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, descending: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'cnpj', label: 'CNPJ', field: 'cnpj', descending: true, sortable: true, align: 'left' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ]

  form = {
    id: null,
    situacao_local_id: null,
    grupo_local_id: null,
    nome: '',
    pai_local_id: null,
    razao_social: '',
    cnpj: null,
    arquivo_cnpj: null,
    logomarca: null,
    estado: null,
    cidade: null,
    bairro: null,
    logradouro: null,
    numero: null,
    complemento: null,
    cep: null,
    latitude: null,
    longitude: null,
    precisao: null,
    numero_whatsapp: null,
    numero_comercial: null,
    cadastro_completo: null,
    segunda_manha_inicio: null,
    segunda_manha_fim: null,
    segunda_tarde_inicio: null,
    segunda_tarde_fim: null,
    terca_manha_inicio: null,
    terca_manha_fim: null,
    terca_tarde_inicio: null,
    terca_tarde_fim: null,
    quarta_manha_inicio: null,
    quarta_manha_fim: null,
    quarta_tarde_inicio: null,
    quarta_tarde_fim: null,
    quinta_manha_inicio: null,
    quinta_manha_fim: null,
    quinta_tarde_inicio: null,
    quinta_tarde_fim: null,
    sexta_manha_inicio: null,
    sexta_manha_fim: null,
    sexta_tarde_inicio: null,
    sexta_tarde_fim: null,
    sabado_manha_inicio: null,
    sabado_manha_fim: null,
    sabado_tarde_inicio: null,
    sabado_tarde_fim: null,
    domingo_manha_inicio: null,
    domingo_manha_fim: null,
    domingo_tarde_inicio: null,
    domingo_tarde_fim: null,
    tipos_locais: [],
    intervalo_minutos_entre_atendimento: null,
    quantidade_atendimentos_por_intervalo: null,
    numero_telefone_privado: null,
    email_comercial: '',
    email_privado: '',
    divulgacao_nome: null,
    divulgacao_tipo: null,
    segmento_id: null,
    valor_minimo_pedido: null,
    valor_entrega_entregador_proprio: null,
    tempo_medio_minutos_preparacao_pedido: null,

    // Auxiliar
    arquivo_cnpj_url_completa: null,
    logomarca_url_completa: null
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
