import { render, staticRenderFns } from "./header.vue?vue&type=template&id=11fc60c7&"
import script from "./header.js?vue&type=script&lang=js&"
export * from "./header.js?vue&type=script&lang=js&"
import style0 from "./header.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDrawer,QBtnDropdown,QAvatar,QList,QItem,QItemSection,QItemLabel,QIcon,QScrollArea,QHeader,QToolbar,QToolbarTitle,QColor,QMenu});
