<template>
  <div class="carregando-pagina full-width full-height fixed" v-show="mostrar" style="z-index: 9999999999;">
    <div class="column bg-primary flex-center full-width full-height">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="400px"
        height="600px"
        viewBox="0 0 181.728 100.312"
        style="max-width: 182px;"
      >
        <g
          id="Grupo_92"
          data-name="Grupo 92"
          transform="translate(-477.637 -196.895)"
        >
          <path
            id="Caminho_10017"
            data-name="Caminho 10017"
            d="M484.466,247.958h6.6a4.146,4.146,0,0,1,2.032.438,1.809,1.809,0,0,1,.95,1.231l0,.018V268.8h3.8V250.313a6.045,6.045,0,0,0-1.757-4.68,7.308,7.308,0,0,0-5.074-1.611h-6.55a7.268,7.268,0,0,0-5.073,1.622,6.047,6.047,0,0,0-1.757,4.669V268.8h3.8v-18a3,3,0,0,1,.672-2.2,3.353,3.353,0,0,1,2.358-.644"
            transform="translate(0 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10018"
            data-name="Caminho 10018"
            d="M493.044,262.378a6.628,6.628,0,0,0,1.34,4.04,5.892,5.892,0,0,0,2.218,1.814,7.39,7.39,0,0,0,3.047.569h13.763v-3.845H499.875a4.268,4.268,0,0,1-1.606-.239,1.731,1.731,0,0,1-.923-.881,6.964,6.964,0,0,1-.5-1.835l0-.014v-1.224l.1-.048,16.175-7.562v-2.885a7.084,7.084,0,0,0-.755-3.387,5.015,5.015,0,0,0-2.191-2.122,7.516,7.516,0,0,0-3.433-.737h-6.167c-2.555,0-4.468.587-5.691,1.744a7.027,7.027,0,0,0-1.837,5.313Zm3.8-6.122v-5.087a3.743,3.743,0,0,1,.72-2.534,2.906,2.906,0,0,1,2.265-.811H506.6a3.159,3.159,0,0,1,2.08.537,2.332,2.332,0,0,1,.633,1.84v.49l-.1.047-12.134,5.674-.242.112Z"
            transform="translate(9.286 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10019"
            data-name="Caminho 10019"
            d="M523.4,244.112a6.579,6.579,0,0,0-4.26,1.869,6.076,6.076,0,0,0-1.353,4.175V262.6a5.778,5.778,0,0,0,3.289,5.343,6.7,6.7,0,0,0,3.5.856H533.9v-3.845h-9.283a2.9,2.9,0,0,1-1.744-.595,3.366,3.366,0,0,1-1.237-2.08,3.025,3.025,0,0,1-.048-.646V250.718a2.581,2.581,0,0,1,1.87-2.659,4.1,4.1,0,0,1,1.024-.236h9.373v-3.8h-9.373a9.562,9.562,0,0,0-1.083.09"
            transform="translate(24.197 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10020"
            data-name="Caminho 10020"
            d="M533.554,267.684a6.029,6.029,0,0,0,3.324,1.117h8.284a5.777,5.777,0,0,0,5.033-3.137,5.948,5.948,0,0,0,.851-3.106V249.615a9.747,9.747,0,0,0-.087-1.082,5.728,5.728,0,0,0-1.917-3.441,6.186,6.186,0,0,0-3.834-1.069H530.634v3.8l14.888.088a1.7,1.7,0,0,1,1.425.814,1.812,1.812,0,0,1,.3,1.021v1.792h-10.4a5.637,5.637,0,0,0-4.547,1.975,8.7,8.7,0,0,0-1.662,5.688v3.039a6.321,6.321,0,0,0,2.92,5.441m.881-9.4a2.012,2.012,0,0,1,.051-.519,3.194,3.194,0,0,1,1.021-1.744,4.153,4.153,0,0,1,2.034-.63l.01,0h6.624l.01,0a4.028,4.028,0,0,1,2.037.643,3.043,3.043,0,0,1,.974,1.737,2.023,2.023,0,0,1,.05.513v4.052a1.812,1.812,0,0,1-.051.474,2.764,2.764,0,0,1-1.056,1.731,5.055,5.055,0,0,1-2.467.418h-5.638a8.008,8.008,0,0,1-1.845-.164,1.941,1.941,0,0,1-1.072-.641,3.852,3.852,0,0,1-.63-1.345,1.792,1.792,0,0,1-.051-.474Z"
            transform="translate(31.943 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10021"
            data-name="Caminho 10021"
            d="M582.734,245.057a7.787,7.787,0,0,0-3.008-.946,7.5,7.5,0,0,0-.994-.09h-7.621c-.234,0-.431,0-.59.013s-.324.019-.5.032c-3.622.412-5.384,2.257-5.384,5.638v13.1a7.008,7.008,0,0,0,.518,2.236,6.244,6.244,0,0,0,1.931,2.585,5.9,5.9,0,0,0,3.887,1.175h7.743a6.374,6.374,0,0,0,3.135-.72,7.718,7.718,0,0,0,1.917-1.433,4.864,4.864,0,0,0,1.078-1.741,6.293,6.293,0,0,0,.338-2.1v-13.1a5.859,5.859,0,0,0-.619-2.768,4.79,4.79,0,0,0-1.83-1.88m-1.351,16.532c0,2.236-1.035,3.367-3.079,3.367h-6.7c-2.1,0-3.17-1.194-3.17-3.547V251.032c0-2.128,1.067-3.209,3.17-3.209h6.7a3.066,3.066,0,0,1,2.274.816,3.286,3.286,0,0,1,.8,2.393Z"
            transform="translate(52.436 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10022"
            data-name="Caminho 10022"
            d="M595.755,267.733A4.838,4.838,0,0,0,597,266.261a5.19,5.19,0,0,0,.854-2.781v-3.962a5.4,5.4,0,0,0-2.172-4.388,6.438,6.438,0,0,0-2.436-1.115,10.343,10.343,0,0,0-1.141-.088h-6.167a2.24,2.24,0,0,1-2.036-1,2.643,2.643,0,0,1-.386-1.241v-1.8a1.785,1.785,0,0,1,.9-1.726,2.853,2.853,0,0,1,1-.335H597.9v-3.8H585.064a5.27,5.27,0,0,0-3.952,1.46,5.666,5.666,0,0,0-1.415,4.1V252.8a4.9,4.9,0,0,0,.678,2.417,5.1,5.1,0,0,0,2.069,2,7.511,7.511,0,0,0,3.374.648h5.635a2.778,2.778,0,0,1,2,.6,2.6,2.6,0,0,1,.6,1.9v1.928a2.6,2.6,0,0,1-.757,2.148,1.59,1.59,0,0,1-1.127.515H579.608V268.8h13.118a5.593,5.593,0,0,0,3.029-1.069"
            transform="translate(61.46 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10023"
            data-name="Caminho 10023"
            d="M550.924,248.413a2.446,2.446,0,0,1,1.534-.5l6.1.01v-3.9H552.12c-.184,0-.534.03-1.037.088a6.148,6.148,0,0,0-3.467,1.438,6.035,6.035,0,0,0-1.359,3.524c-.058.617-.09,1.1-.09,1.42V268.8h3.8V250.584a3.5,3.5,0,0,1,.194-1.329,1.929,1.929,0,0,1,.761-.841"
            transform="translate(41.305 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10024"
            data-name="Caminho 10024"
            d="M560.158,248.413a2.446,2.446,0,0,1,1.534-.5l6.1.01v-3.9h-6.437c-.184,0-.534.03-1.037.088a6.148,6.148,0,0,0-3.467,1.438,6.036,6.036,0,0,0-1.359,3.524c-.059.617-.09,1.1-.09,1.42V268.8h3.8V250.584a3.532,3.532,0,0,1,.192-1.329,1.933,1.933,0,0,1,.763-.841"
            transform="translate(46.87 28.405)"
            fill="#fff"
          />
          <path
            id="Caminho_10025"
            data-name="Caminho 10025"
            d="M520.937,250.981v-3.8h-8.586v-8.4h-3.8v33.175h3.8V250.981h8.586Z"
            transform="translate(18.632 25.248)"
            fill="#fff"
          />
          <path
            id="Caminho_10026"
            class="b"
            data-name="Caminho 10026"
            d="M545.72,196.895a30.287,30.287,0,1,0,30.287,30.287,30.288,30.288,0,0,0-30.287-30.287m-18.678,20.842a5.537,5.537,0,0,1,1.609-4.273,6.655,6.655,0,0,1,4.645-1.483h6a6.674,6.674,0,0,1,4.643,1.473,5.53,5.53,0,0,1,1.608,4.282v16.926h-3.48V217.126l0-.014a1.636,1.636,0,0,0-.869-1.127,3.763,3.763,0,0,0-1.859-.4H533.3a3.077,3.077,0,0,0-2.16.588,2.746,2.746,0,0,0-.614,2.019v16.471h-3.479ZM564.4,242.383H527.042v-3.6H564.4Zm0-7.72h-8.539a6.143,6.143,0,0,1-3.2-.784,5.292,5.292,0,0,1-3.01-4.891V217.594a5.561,5.561,0,0,1,1.237-3.821,6.025,6.025,0,0,1,3.9-1.713,8.855,8.855,0,0,1,.99-.079h8.581v3.48h-8.581a3.823,3.823,0,0,0-.938.213,2.362,2.362,0,0,0-1.712,2.435V228.1a2.77,2.77,0,0,0,.045.591A3.072,3.072,0,0,0,554.3,230.6a2.662,2.662,0,0,0,1.6.545h8.5Z"
            transform="translate(22.78 0)"
            fill="#fdb913"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarregandoGeral',
  props: {
    mostrar: { type: Boolean, default: true },
    tipo: { type: String, default: 'fixo' }
  }
}
</script>

<style lang="scss">
.carregando-pagina {
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-top: 10px;
    color: #363f46;
  }
}

.b {
  position: relative;
  animation: inercia 500ms ease-in infinite alternate,
    pular 1000ms ease-in-out infinite alternate;
}
@keyframes pular {
  0% {
    border-width: 0vh 5vh 0 0;
  }
  100% {
    border-width: 0 0 5vh 0;
  }
}

@keyframes inercia {
  0% {
    transform: translate3d(40%, 0px, 0) scale3d(0.92, 1.05, 1);
  }
  100% {
    transform: translate3d(-20px, -30px, 0) scale3d(1.1, 0.8, 1);
  }
}
</style>
