export default function () {
  return {
    id: null,
    email: null,
    token: null,
    nome: null,
    foto: null,
    roles: []
  }
}
