import { render, staticRenderFns } from "./Beneficios.vue?vue&type=template&id=af204b9a&"
import script from "./Beneficios.js?vue&type=script&lang=js&"
export * from "./Beneficios.js?vue&type=script&lang=js&"
import style0 from "./Beneficios.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QColor});
