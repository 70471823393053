import { EventBus } from 'src/core/event/EventBus'
import menu from '../constants/menu'
import usuarioLoginStore from 'src/store/usuario-login'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
export default {
  name: 'Header',
  data () {
    return {
      scrollPosition: null,
      buscaCarregando: false,
      fotoPerfil: 'avatar.jpg',
      buscaGeralSelect: '',
      valorDigitadoBusca: '',
      buscaGeralItensRaw: [],
      buscaGeralItens: [],
      listaMarcaModelo: [],
      textoBusca: '',
      forcerRender: 1,
      abrirMenu: false,
      navMenu: [],
      itensMenu: [
        {
          nome: 'comprar',
          class: 'text-center',
          label: 'Comprar'
        },
        {
          nome: 'vender',
          class: 'text-center',
          label: 'Vender ou trocar'
        },
        {
          nome: 'assinatura',
          class: 'text-center',
          label: 'Assinatura de carro'
        },
        {
          nome: 'financiamento',
          class: 'text-center',
          label: 'Financiamento'
        },
        {
          nome: 'quem-somos',
          class: 'text-center',
          label: 'Sobre nós'
        },
        {
          nome: 'unidades',
          class: 'text-center',
          label: 'Unidades'
        }
        // {
        //   nome: 'faq',
        //   class: 'text-center',
        //   label: 'FAQ'
        // }
      ]
    }
  },
  methods: {
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
    irHome () {
      this.$router.push({ name: 'home' })
    },
    selecionarBusca (valor) {
      const elementoRaw = this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)].split(',')
      if (elementoRaw.length > 1 && this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)].indexOf(',')) {
        if (window.location.href.indexOf('comprar') === -1) {
          this.$router.push({
            name: 'comprar',
            query: {
              'valor_venda_pf': 'Faixa+de+Preço: ' + valor + ' |' + this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)]
            }
          })
        } else {
          EventBus.$emit('faixa_preco', { min: elementoRaw[0], max: elementoRaw[1] })
        }
      } else {
        // const elementoRaw = this.buscaGeralItensRaw[this.buscaGeralItens.indexOf(valor)].split('|')
        const item = this.buscaGeralItensRaw.find(i => {
          if (i === valor) {
            return true
          } else {
            const parts = i.split('|')
            if (parts.length > 1 && (parts[0] + ' ' + parts[1]) === valor) {
              return true
            }
          }
          return false
        })
        const elementoRaw = item.split('|')
        if (elementoRaw.length > 1) {
          if (window.location.href.indexOf('comprar') === -1) {
            this.$router.push({
              name: 'comprar',
              query: {
                'marca_objeto.marca_nome.keyword': 'Marca:,,,' + elementoRaw[0] + ' |' + elementoRaw[0] + ' ',
                'modelo_objeto.modelo_nome.keyword': 'Modelo:,,,' + elementoRaw[1].replace(' ', ',,,') + ' |' + elementoRaw[1].replace(' ', ',,,') + ' '
              }
            })
          } else {
            EventBus.$emit('busca_marca_modelo', { marca: elementoRaw[0], modelo: elementoRaw[1] })
          }
        } else {
          if (window.location.href.indexOf('comprar') === -1) {
            this.$router.push({
              name: 'comprar',
              query: {
                'marca_objeto.marca_nome.keyword': 'Marca:,,,' + elementoRaw[0] + ' |' + elementoRaw[0] + ' '
              }
            })
          } else {
            EventBus.$emit('busca_marca_modelo', { marca: elementoRaw[0] })
          }
        }
      }
    },
    filterFn (valor, update, abort) {
      let val = valor.replace('R$', '').replace('.', '').replace(',00', '').replace('mil', '000').trim()
      if (val.length < 2) {
        abort()
        return
      }

      let valorBusca = parseInt(val.replace(' ', ''))

      update(() => {
        if (!isNaN(valorBusca)) {
          if (valorBusca.toString().length < 4) {
            valorBusca = 1000 * valorBusca
          }
          if (valorBusca < 35000) {
            this.buscaGeralItens = ['Carros de 0 a R$ 35 mil reais']
            this.buscaGeralItensRaw = ['0,35000']
          } else if ((valorBusca >= 35000) && (valorBusca < 55000)) {
            this.buscaGeralItens = ['Carros de R$ 35 mil reais a R$ 55 mil reais']
            this.buscaGeralItensRaw = ['35000,55000']
          } else if ((valorBusca >= 55000) && (valorBusca < 75000)) {
            this.buscaGeralItens = ['Carros de R$ 55 mil reais a R$ 75 mil reais']
            this.buscaGeralItensRaw = ['55000,75000']
          } else {
            this.buscaGeralItens = ['Carros de mais de R$ 75 mil reais']
            this.buscaGeralItensRaw = ['75000,3000000']
          }
        } else {
          this.buscaCarregando = true
          const requisicoesBusca = [{ tipo: 'geral', termo: 'busca_geral', label: 'Busca: ' + val, valor: val }]
          this.elasticSearchModel.buscar(requisicoesBusca, this.$store).then((retorno) => {
            const marcas = [...new Set(retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key.split('|')[0]))].filter((item) => item.toUpperCase().indexOf(this.textoBusca.toUpperCase()) > -1)
            this.buscaGeralItensRaw = [...new Set(retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key))]
            this.buscaGeralItensRaw = marcas.concat(this.buscaGeralItensRaw)
            this.buscaGeralItens = [...new Set(retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key.split('|')[0] + ' ' + item.key.split('|')[1]))]
            this.buscaGeralItens = marcas.concat(this.buscaGeralItens)
          })
        }
      })
    },
    textoInputBusca (val) {
      this.textoBusca = val
    },
    atualizarBusca () {
      if (this.buscaGeralSelect !== '') {
        if (window.location.href.indexOf('comprar') > -1) {
          EventBus.$emit('busca_geral', this.buscaGeralSelect)
        } else {
          this.$router.push({ name: 'comprar', query: { busca_geral: this.buscaGeralSelect } })
        }
      }
    }
  },
  created () {
    this.navMenu = menu
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    // if (this.usuarioLogado) {
    //   this.itensMenu.push(
    //     {
    //       nome: 'sair',
    //       icon: 'app:sair_2',
    //       label: 'Sair'
    //     }
    //   )
    // }
    if (this.logado && this.logado.foto) {
      this.fotoPerfil = this.logado.foto
      this.forcerRender++
    }

    this.elasticSearchModel = new ElasticSearchModel()

    const executarBusca = async () => {
      this.elasticSearchModel.buscar([], this.$store).then((retorno) => {
        this.buscaGeralItensRaw = retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key)
        this.buscaGeralItens = retorno.aggregations.marca_modelo_versao.buckets.map((item) => item.key.split('|')[0] + ' ' + item.key.split('|')[1])
      })
    }
    executarBusca()
  },
  computed: {
    usuarioLogado () {
      return this.$store.getters['usuario-login/getNome'] ?? null
    },
    buscaGeralItensC () {
      return (!this.buscaCarregando && this.buscaGeralItens.length > 0) ? this.buscaGeralItens : []
    },
    logado: {
      get () {
        return this.$store.getters['usuario-login/get'] ?? null
      }
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('usuario-login')) this.$store.registerModule('usuario-login', usuarioLoginStore)
  }
}
