import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CalcularValorParcelaModel extends PadraoModel {
  recurso = 'calcular-valor-parcela';
  microServico = 'negociacao';
  include = null;
  searchFixo = null;
  nome = 'Calcular Valor Parcela';

  colunasGrid = []

  form = {
    ano_modelo: null,
    porcentagem_entrada: 20,
    qtd_parcelas: null,
    placa_uf: null,
    valor: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
