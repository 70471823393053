import PadraoModel from 'src/core/model/PadraoModel.js'

export default class ClienteModel extends PadraoModel {
  recurso = 'clientes';
  microServico = 'usuario';
  include = 'situacao,roles';
  searchFixo = null;
  nome = 'Usuário';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'celular', label: 'Celular', field: 'celular', align: 'left', descending: true, sortable: true, buscaTipo: 'texto' },
    { name: 'documento', label: 'CPF', field: 'documento', align: 'left', descending: true, sortable: true, buscaTipo: 'texto' },
    { name: 'email', label: 'E-mail', field: 'email', align: 'left', descending: true, sortable: true, buscaTipo: 'texto' },
    { name: 'situacao_id', label: 'Situação', field: 'situacao_usuario.nome', align: 'left', descending: true, sortable: true, buscaTipo: 'texto', orderByCustomizado: 'situacoes_usuarios:situacao_id|situacoes_usuarios.nome' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    nome: '',
    email: '',
    celular: '',
    documento: '',
    origem_whitelabel_id: null,
    senha: null,
    senha_confirmation: null,
    situacao_id: '',
    roles: [9],
    senha_por_email: 0,
    carteira_id: '',
    unidade_negocio_id: '',
    grupo_cliente_id: '',
    grupos_clientes: [],
    whitelabels: [],
    como_conheceu_id: null,
    aceitar_publicidade_sms: 0,
    aceitar_publicidade_email: 0,
    aceitar_publicidade_whatsapp: 0,
    aceitar_publicidade_icarros: 1,
    responsavel_local_id: null,
    responsavel_usuario_id: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
