import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CategoriaModel extends PadraoModel {
  recurso = 'categorias';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Categorias';

  colunasGrid = [
    {
      name: 'acoes',
      label: 'Ações',
      field: 'acoes',
      sortable: false,
      style: 'width: 50px',
      align: 'left'
    },
    {
      name: 'id',
      label: 'Cód',
      field: 'id',
      sortable: true,
      descending: true,
      align: 'left',
      buscaTipo: 'inteiro'
    },
    {
      name: 'nome',
      label: 'Nome',
      field: 'nome',
      descending: true,
      sortable: true,
      align: 'left',
      buscaTipo: 'texto'
    },
    {
      name: 'uri',
      label: 'URI Interno',
      field: 'uri',
      descending: true,
      sortable: true,
      align: 'left',
      buscaTipo: 'texto'
    },
    {
      name: 'ativo',
      label: 'Ativo',
      field: 'ativo',
      sortable: true,
      align: 'left',
      buscaTipo: 'boleanoSim'
    },
    {
      name: 'prioridade',
      label: 'Prioridade',
      field: 'prioridade',
      sortable: true,
      align: 'left',
      buscaTipo: 'inteiro'
    },
    {
      name: 'created_at',
      label: 'Criado em',
      field: 'created_at',
      sortable: true,
      align: 'left',
      buscaTipo: 'dataHoraMinuto'
    },
    {
      name: 'updated_at',
      label: 'Atualizado em',
      field: 'updated_at',
      sortable: true,
      align: 'left',
      buscaTipo: 'dataHoraMinuto'
    }
  ];

  form = {
    id: 1,
    nome: '',
    uri: '',
    foto: null,
    foto_url_completa: null,
    prioridade: 1,
    ativo: 1,
    grupo_categoria_id: null,
    termo_carroceria: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
