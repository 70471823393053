<template>
  <div :class="tipo === 'fixo' ? 'carregando-pagina' : 'carregando-absolute'" v-show="mostrar">
    <div class="column flex-center">
      <q-circular-progress
        indeterminate
        size="40px"
        color="primary"
        class="q-ma-md"
      />
      <p>Carregando</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Carregando',
  props: {
    mostrar: { type: Boolean, default: false },
    tipo: { type: String, default: 'fixo' }
  }
}
</script>

<style lang="scss">
.carregando-pagina {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  & p {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-top: 10px;
    color: #363f46;
  }
}
.carregando-absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  & p {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-top: 10px;
    color: #363f46;
  }
}
</style>
