import axios from 'axios'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import CardArquivo from 'src/components/Card/CardArquivo.vue'

export default {
  name: 'Upload',
  mixins: [NotificacaoMixin],
  components: { CardArquivo },
  data () {
    return {
      response: null,

      file: null,
      previa: null,
      imgAtual: null,
      enviando: false,
      sucesso: false,
      erro: false,
      validacao: false,
      carregando: false,
      resultado: null
    }
  },
  watch: {
    'dados.imagem': {
      handler (agora, antes) {
        if (agora && !antes) {
          this.criarImagemAtual(agora)
        }
      }
    }
  },
  props: {
    dados: Object,
    todos: {
      type: Boolean,
      default: false
    },
    microServico: {
      type: String,
      default: 'usuario'
    },
    path: {
      type: String,
      default: '/usuario/upload-arquivos'
    }
  },
  methods: {
    processarImagem (e) {
      this.validacao = false
      const file = e.target.files[0]
      if (file) {
        if (this.todos) {
          this.file = file
          this.criarPrevia(true)
        } else {
          if (
            file.size > 102400000 ||
            (file.type !== 'image/png' &&
              file.type !== 'image/jpg' &&
              file.type !== 'image/jpeg' &&
              file.type !== '')
          ) {
            this.validacao = true
            this.removerImagem()
            this.notificacao('erro', 'Imagem inválida')
          } else {
            this.file = file
            this.criarPrevia()
          }
        }
      }
    },
    removerImagem () {
      this.file = null
      this.previa = null
    },
    criarPrevia (soEnviar) {
      if (!soEnviar) {
        this.previa = window.URL.createObjectURL(this.file)
      }
      this.enviarImagem(this.file, false)
    },
    criarImagemAtual (url) {
      if (url !== null) {
        this.previa = null
        this.imgAtual = url
      }
    },
    enviarImagem (foto, emitResponse) {
      const arquivo = new FormData()
      this.imgAtual = null
      arquivo.append('arquivo', foto, foto.name)
      this.enviando = true
      this.sucesso = false
      this.erro = false
      const url = process.env.API[this.microServico] + this.path
      axios
        .post(url, arquivo, null)
        .then(response => {
          this.enviando = false
          this.sucesso = true
          this.$emit('arquivo', { arquivo: response.data.dados.arquivo, arquivo_url_completa: response.data.dados.arquivo_url_completa })
          this.previa = null
          this.response = response
          this.resultado = response.data.dados.arquivo_url_completa
          this.imgAtual = response.data.dados.arquivo_url_completa
        })
        .catch(() => {
          this.enviando = false
          this.erro = true
        })
    },
    abrirLink () {
      window.open(this.imgAtual, '_blank')
    },
    excluir () {
      this.imgAtual = null
      this.$emit('arquivo', { arquivo: '', arquivo_url_completa: '' })
    },
    dragStart (e) {
      e.dataTransfer.setData('text', 'data')
      e.dataTransfer.effectAllowed = 'move'
    },
    dragOver (e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'move'
    },
    drop (e) {
      e.preventDefault()
      e.stopPropagation()
      this.file = e.dataTransfer.files[0]
      this.criarPrevia()
    }
  },
  mounted () {
    const ref = this.$refs.upload
    if (this.dados.imagem) {
      this.criarImagemAtual(this.dados.imagem)
    }
    ref.addEventListener('dragstart', this.dragStart, false)
    ref.addEventListener('dragover', this.dragOver, false)
    ref.addEventListener('drop', this.drop, false)
  }
}
