import PadraoModel from 'src/core/model/PadraoModel.js'

export default class AgendamentoModel extends PadraoModel {
  recurso = 'agendamentos';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'Cliente';

  colunasGrid = [];

  form = {
    id: null,
    usuario_id: null,
    anuncio_id: null,
    tipo: null,
    situacao: 'Agendado',
    data_atendimento: null,
    local_id: null,
    cep: null,
    numero: null,
    bairro: null,
    cidade: null,
    rua: null,
    complemento: null,
    estado: null,
    ponto_referencia: null,
    celular: null,
    latitude: null,
    longitude: null,
    custo: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
