export function getNome (state) {
  return state.nome !== null ? state.nome : null
}

export function getId (state) {
  return state.id
}

export function get (state) {
  return {
    id: state.id,
    nome: state.nome,
    email: state.email,
    token: state.token,
    foto: state.foto,
    roles: state.roles
  }
}
