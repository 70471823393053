import PadraoModel from 'src/core/model/PadraoModel.js'

export default class MolicarModel extends PadraoModel {
  recurso = 'molicar';
  microServico = 'automotor';
  include = '';
  searchFixo = null;
  nome = 'Molicar';

  colunasGrid = [];

  form = {
    id: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
