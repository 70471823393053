import { date } from 'quasar'

export default {
  name: 'CardArquivo',
  props: {
    dados: Object,
    index: Number
  },
  methods: {
    abrirDownload (arquivo) {
      window.open(arquivo, '_blank')
    }
  },
  filters: {
    esconderNome (nome, extensao) {
      let semExtensao = nome.split('.')[0]
      let nomeReal = semExtensao.substr(0, 5) + `...${this.index + 1}` + `.${extensao}`
      return nomeReal
    },
    dateBr (value) {
      if (value) {
        return date.formatDate(value, 'DD/MM/YYYY HH:mm:ss')
      }
    },
    tamanhoMB (totalsize) {
      var totalSizeMB = (totalsize / Math.pow(1024, 2)).toFixed(2) + ' MB'
      return totalSizeMB
    }
  }
}
