// export const getBusca = (state) => {
//   return state.busca ? state.busca : []
// }
export const getDados = (state) => {
  return state.dados ?? []
}
// export const getColunasGrid = (state) => {
//   return state.colunasGrid
// }
// export const getColunasVisiveisGrid = (state) => {
//   return state.colunasVisiveisGrid
// }
export const getFiltros = (state) => {
  return state.filtros
}
export const getDialogAuxiliar = (state) => {
  return state.dialogAuxiliar
}
export const getDialogAuxiliarMostrar = (state) => {
  return state.dialogAuxiliar.mostrar
}
export const getPagination = (state) => {
  // if (process.env.DEV === true) { console.warn('organizar o pagination') }
  // fazer o computed por conta de rodar junto
  return state.pagination
}
// export const getPaginationCarregando = (state) => {
//   // console.log('getPaginationCarregando')
//   return state.paginationCarregando
// }
// export const getForm = (state) => {
//   return state.form
// }
// export const getFormInfo = (state) => {
//   return state.formInfo
// }
// export const getConfiguracoesModel = (state) => {
//   return state.dadosModel
// }
// export const getListagem = (state) => {
//   return state.listagem
// }
// export const getFiltros = (state) => {
//   return state.filtros
// }

// export const getFiltrosParaSearch = (state) => {
//   // let filtrox = []
//   // if (process.env.DEV === true) { console.warn('state.colunasGrid', state.colunasGrid) }
//   // let colunasGrid = [Object.assign(state.colunasGrid)]

//   // console.warn('colunasGrid', colunasGrid)
//   // console.warn('state.filtros', state.filtros)
//   // if (state.filtros) { filtrox = state.filtros.map((elem, index, arr) => elem.campo + ':' + elem.valor).join(';') }
//   // if (state.filtros) {
//   //   let filtrosTmp = [Object.values(extend(true, {}, state.filtros))]
//   //   filtrox = filtrosTmp[0].map((elem, index, arr) => {
//   //     let buscaField = colunasGrid2[0].filter((item, key) => {
//   //       if (item.name === elem.campo) { return item.field }
//   //     })
//   //     if (buscaField.length > 0) { elem.campo = buscaField[0].field }

//   //     return elem.campo + ':' + elem.valor
//   //   }).join(';')
//   // }
//   // console.warn('oioioi', (state.dadosModel.searchFixo ? state.dadosModel.searchFixo + `${state.filtros.length !== 0 ? ';' : ''}` : '') + filtrox)
//   return (state.dadosModel.searchFixo ? state.dadosModel.searchFixo : '')
// }
