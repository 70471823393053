import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'CategoriaCarrossel',
  components: { VueSlickCarousel },
  props: {
    title: {
      type: String,
      default: 'Encontre o carro ideal'
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    categorias: [
      {
        img: 'home/categorias/carros-para-familia',
        nome: 'Carros Para Família',
        class: 'btn-categoria-carros-para-familia',
        nome_sem_tags: 'Carros Para Família'
      },
      {
        img: 'home/categorias/carros-de-aventura',
        nome: 'Carros Aventureiros',
        class: 'btn-categoria-carros-aventureiros',
        nome_sem_tags: 'Carros Aventureiros'
      },
      {
        img: 'home/categorias/carros-compactos',
        nome: 'Carros Compactos',
        class: 'btn-categoria-carros-compactos',
        nome_sem_tags: 'Carros Compactos'
      },
      {
        img: 'home/categorias/carros-economicos',
        nome: 'Carros Econômicos',
        class: 'btn-categoria-carros-economicos',
        nome_sem_tags: 'Carros Econômicos'
      }
    ],
    carrosselCards: {
      // lazyLoad: 'ondemand',
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: false,
      responsive: [
        {
          breakpoint: 2400,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 599,
          settings: {
            rows: 2,
            slidesToShow: 1.1,
            slidesToScroll: 1
          }
        }
      ]
    }
  }),
  methods: {
    buscarTag (value) {
      window.location.href = '/comprar?tags_busca_nome.keyword=Tag: ' + value + '+|' + value
    }
  }
}
