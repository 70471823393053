import PadraoModel from 'src/core/model/PadraoModel.js'

export default class RoleModel extends PadraoModel {
  recurso = 'auth/forgot-password';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'Recuperar senha';

  colunasGrid = [];

  form = {
    login: null,
    usuario_id: null,
    codigo: null,
    senha: null,
    senha_confirmation: null
  };

  constructor (alterar) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (alterar) {
      this.recurso += '/alterar-senha'
    }
  }
}
