var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dados && _vm.dados.id)?_c('router-link',{staticClass:"card-veiculo",class:_vm.classe,attrs:{"target":"_blank","title":_vm.dados ? _vm.nomeVeiculo : '',"to":{
    name: 'veiculo',
    params: {
      marca: _vm.objetos.marca,
      modelo: _vm.objetos.modelo,
      versao: _vm.objetos.versao,
      ano: _vm.objetos.ano,
      id: _vm.dados.id
    }
  }}},[_c('div',{staticClass:"vertical"},[_c('div',{staticClass:"tags"},_vm._l((_vm.getTags),function(tag,index){return _c('CardTag',{key:index,attrs:{"type":tag.type,"value":tag.value}})}),1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"container-img"},[_c('q-img',{attrs:{"contain":false,"position":"inherit","spinner-color":"primary","src":_vm._f("imagemOriginal")(_vm.fotoMini),"alt":_vm.dados ? _vm.nomeVeiculo : ''}})],1),_c('div',{staticClass:"container-dados"},[_c('div',{staticClass:"spec-wrapper"},[_c('div',{staticClass:"t1_"},[_vm._v("\n            "+_vm._s(_vm.marcaModelo)+"\n          ")]),_c('div',{staticClass:"container-data"},[_c('div',[_vm._v("\n              "+_vm._s(_vm.dados.km ? ((_vm.dados.km.toLocaleString()) + " km") : "0 km")+"\n            ")]),(_vm.dados.ano_fabricacao && _vm.dados.ano_modelo)?_c('div',[_vm._v("\n              "+_vm._s(((_vm.dados.ano_fabricacao
                  .toString()
                  .substr(-2)) + "/" + (_vm.dados.ano_modelo.toString().substr(-2))))+"\n            ")]):_vm._e()]),_c('h2',{staticClass:"t2_"},[_vm._v(_vm._s(_vm.nomeVeiculo))])]),_c('div',[(_vm.dados.situacao_id !== 9 && _vm.dados.situacao_id !== 20)?_c('div',{staticClass:"prices_row"},[_c('div',{staticClass:"dashed",class:{ 'visible': _vm.dados.valor_anterior_pf && _vm.dados.valor_anterior_pf > _vm.valorVeiculo }},[_c('span',[_vm._v("R$")]),_vm._v("\n              "+_vm._s(_vm.dados.valor_anterior_pf
                  ? _vm.dados.valor_anterior_pf.toLocaleString("de-DE", {
                      maximumFractionDigits: 0
                    })
                  : "")+"\n            ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.valorVeiculo),expression:"valorVeiculo"}],staticClass:"t3_"},[_vm._v("\n              R$\n              "),_c('b',[_vm._v(_vm._s(_vm.valorVeiculo
                  ? _vm.valorVeiculo.toLocaleString("de-DE", {
                      maximumFractionDigits: 0
                    })
                  : ""))]),_vm._v("\n              em\n            ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.valorParcela),expression:"valorParcela"}],staticClass:"t4_"},[_c('b',[_vm._v(_vm._s(_vm.dados.qtd_parcelas)+"x")]),_vm._v(" de R$\n              "),_c('strong',[_vm._v("\n                "+_vm._s(_vm.valorParcela
                    ? _vm.valorParcela.toLocaleString("pt-BR", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    : "")+"\n              ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dados.qtd_parcelas == 47),expression:"dados.qtd_parcelas == 47"}],staticClass:"t6_"},[_vm._v("+ parcela final")]),_c('div',{staticClass:"car-location"},[_c('i',{staticClass:"nc-pin-marker"}),_c('span',[_vm._v("\n                "+_vm._s(_vm.dados &&
                  _vm.dados.proprietario_local &&
                  _vm.dados.proprietario_local.nome
                    ? _vm.dados.proprietario_local.nome
                    : "")+_vm._s(_vm.dados &&
                  _vm.dados.proprietario_local &&
                  _vm.dados.proprietario_local.cidade
                    ? (", " + (_vm.dados.proprietario_local.cidade))
                    : "")+"\n              ")])])]):_vm._e(),_c('div',{staticClass:"full-width"},[_c('q-btn',{staticClass:"btn-primary-outline full-width",attrs:{"unelevated":"","no-caps":"","label":"Ver mais detalhes","icon-right":"app:icon-chevron-right"}})],1)])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }