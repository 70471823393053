export default {
  name: 'DpGridAcoes',
  props: {
    linha: Object,
    naoEditar: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {

    }
  },
  methods: {
  }
}
