import PadraoModel from 'src/core/model/PadraoModel.js'

export default class OlxModeloModel extends PadraoModel {
  recurso = 'olx/modelos';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Modelos Olx';

  colunasGrid = [
  ];

  form = {
    id: null,
    nome: null
  };

  constructor (marca) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (marca) this.recurso += `/${marca}`
  }
}
