import PadraoModel from 'src/core/model/PadraoModel.js'

export default class BemModel extends PadraoModel {
  recurso = 'bens';
  microServico = 'automotor';
  include = 'versao.modelo.marca.categoria,cor,situacao,cambio,carroceria,cambio,tipo_retomada,combustivel';
  searchFixo = null;
  nome = 'Veículos';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', descending: true, sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'marca', label: 'Marca', field: 'versao.modelo.marca.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'versoes:versao_id.modelos:modelo_id.marcas:marca_id|marcas.nome' },
    { name: 'modelo', label: 'Modelo', field: 'versao.modelo.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'versoes:versao_id.modelos:modelo_id.marcas:marca_id|modelos.nome' },
    { name: 'placa', label: 'Placa', field: 'placa', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'situacao', label: 'Situação', field: 'situacao.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    cor_id: null,
    proprietario_local_id: null,
    situacao_id: 1,
    versao_id: null,
    combustivel_id: null,
    tipo_retomada_id: null,
    carroceria_id: null,
    cambio_id: null,
    nome: null,
    descricao: '',
    placa: null,
    motor: null,
    chassi: '',
    ano_fabricacao: null,
    ano_modelo: null,
    km: null,
    qtd_portas: null,
    qtd_eixos: null,
    blindado: 0,
    destaque: 0,
    renavam: null,
    numero_motor_atual: null,
    numero_motor_original: null,
    potencia: null,
    cilindradas: null,
    placa_uf: null,
    placa_cidade: null,
    aprovado_laudo: null,
    ultimo_proprietario_nome: null,
    ultimo_proprietario_documento: null,
    ultimo_proprietario_tipo_documento: null,
    chassi_adulterado: null,
    contrato: null,
    foto: null,
    foto_url_completa: null,
    video_youtube: null,
    valor_fipe: 0.00,
    valor_mercado: 0.00,
    valor_reparos: 0.00,
    valor_reservar: 0.00,
    prazo_dias_apos_reserva: null,
    estoque_local_id: null,
    laudo_arquivo: null,
    placa_quantidade_versoes: null,
    codigo_molicar: null,
    codigo_molicar_1: null,
    codigo_fipe: null,
    credere_fuel_type: null,
    zero_km: 0,
    precificador_versao_id: 0,
    spincar: null,
    //
    categoria_id: null,
    marca_id: null,
    modelo_id: null,
    versao: null
  };

  constructor (arquivos) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (arquivos) {
      this.recurso = 'bens-arquivos'
      this.include = ''
    }
  }
}
