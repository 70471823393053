import UsuarioModel from 'src/core/model/UsuarioModel'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import { LocalStorage, clone } from 'quasar'
import usuarioLoginStore from 'src/store/usuario-login/'
import RecuperarSenhaModel from 'src/model/usuario/RecuperarSenhaModel'
import GoogleLogin from 'vue-google-login'
import axios from 'axios'

export default {
  name: 'MixinCadastro',
  components: { GoogleLogin },
  mixins: [NotificacaoMixin],
  data () {
    return {
      logado: LocalStorage.getItem('logado'),
      modelRecuperarSenha: new RecuperarSenhaModel(),
      modelRecuperarSenhaConfirmar: new RecuperarSenhaModel(true),

      salvando: false,
      requisitandoSenha: false,
      repetirSenha: '',

      error: false,

      usuarioModel: new UsuarioModel(),
      cadastrando: false,
      form: {
        nome: '',
        email: '',
        documento: null,
        senha: '',
        senha_confirmation: '',
        celular: '',
        tipo: 1,
        google_id: null,
        facebook_id: null
      },

      passosCredere: LocalStorage.getItem('passosCredereDetalhes'),
      bitrixdadoscadastro: LocalStorage.getItem('bitrixdadoscadastro'),

      // Redes Socias
      fbSignInParams: {
        scope: 'public_profile,email',
        return_scopes: true
      },

      paramsGoogleLogin: {
        client_id: '244983736592-6hlup8t7t01uijq8mqtj6vbn40mthr2u.apps.googleusercontent.com'
      }
    }
  },
  mounted () {
    if (!this.$store.hasModule('usuario-login')) this.$store.registerModule('usuario-login', usuarioLoginStore)
    const passosCredereDetalhes = this.$store.getters['depois/getData']
    if (passosCredereDetalhes && passosCredereDetalhes.name) {
      this.form.documento = passosCredereDetalhes.cpf_cnpj
      this.form.nome = passosCredereDetalhes.name
      this.form.celular = passosCredereDetalhes.phone_number
      this.form.email = passosCredereDetalhes.email
    }

    if (this.bitrixdadoscadastro) {
      this.form.documento = this.bitrixdadoscadastro.documento
      this.form.nome = this.bitrixdadoscadastro.nome
      this.form.celular = this.bitrixdadoscadastro.celular
      this.form.email = this.bitrixdadoscadastro.email
    }
  },
  methods: {
    async cadastrar () {
      if (!this.form.google_id) delete this.form.google_id
      if (!this.form.facebook_id) delete this.form.facebook_id

      const form = Object.assign({}, { ...this.usuarioModel.form, ...this.form })
      if (form.senha !== form.senha_confirmation) {
        return this.notificacao('erro', 'Senhas não correspondem')
      }
      this.cadastrando = true
      try {
        const response = await this.usuarioModel.cadastrar({ ...form, situacao_id: 1 })
        this.$refs.cadastro.resetValidation()
        axios.defaults.headers.common.Authorization = 'Bearer ' + response.dados.token
        this.notificacao('salvo', `Seja bem vindo ${form.nome}`)

        // this.irRota({ name: 'painel-inicio' })
        this.$store.dispatch('usuario-login/setLogado', { ...response.dados,
          roles: [{
            id: 2,
            name: 'Comprador',
            prioridade: 1 }]
        })

        this.usuarioModel
          .login2({
            login: form.email,
            senha: form.senha,
            origem_whitelabel_id: 1
          })
          .then(retorno => {
            if (retorno.erro === 0) {
              this.$store.dispatch('usuario-login/setLogado', retorno.dados)
              LocalStorage.set('lembrarLogin', this.lembrar)
              axios.defaults.headers.common.Authorization = 'Bearer ' + retorno.dados.token
            }
          }).catch(erro => {
            console.error('erro', erro)
            this.notificacao('erro', 'Erro não identificado')
          })

        LocalStorage.remove('bitrixdadoscadastro')

        const passosCredereDetalhes = LocalStorage.getItem('passosCredereDetalhes')

        if (this.bitrixdadoscadastro) {
          this.irRota({ name: 'painel-agendamento-inicio', params: { id: this.bitrixdadoscadastro.id, publico: this.bitrixdadoscadastro.publico } })
        } else if ((passosCredereDetalhes && passosCredereDetalhes.simulacaoForm)) {
          this.irRota({ name: 'painel-proposta', params: { id: passosCredereDetalhes.id, publico: this.passosCredere.publico } })
        } else if (this.form.tipo === 3) {
          this.irRota({ name: 'home' })
        } else {
          this.irRota({ name: 'painel-inicio' })
        }

        Object.keys(this.form).forEach((key) => { this.form[key] = '' })
      } catch (erros) {
        if (erros.length > 0) { erros.map((erro) => this.notificacao('erro', erro)) } else { this.notificacao('erro', erros) }
        this.cadastrando = false
        this.error = true
      }
    },
    efetuarLogin () {
      const aviso = this.notificacao('enviando', 'Entrando...')
      this.salvando = true

      this.usuarioModel.login2({ login: this.email, senha: this.senha, origem_whitelabel_id: 1 }).then((retorno) => {
        this.salvando = false
        aviso()

        if (retorno.erro === 0) {
          const notify = this.notificacao('salvo', 'Bem vindo!')
          this.$store.dispatch('usuario-login/setLogado', retorno.dados)
          LocalStorage.set('lembrarLogin', this.lembrar)
          axios.defaults.headers.common.Authorization = 'Bearer ' + retorno.dados.token

          LocalStorage.remove('bitrixdadoscadastro')

          setTimeout(() => notify(), 3000)
          if (this.passosCredere) {
            this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id, publico: this.passosCredere.publico } })
          } else {
            this.irRota({ name: 'painel-inicio' })
          }
        } else {
          this.notificacao('erro', retorno.msg)
        }
      }).catch((erro) => {
        this.salvando = false
        aviso()
        console.error('erro', erro)
        this.notificacao('erro', 'Erro não identificado')
      })
    },
    requisitarSenha () {
      this.requisitandoSenha = true
      const form = clone(this.modelRecuperarSenha.form)
      this.modelRecuperarSenha.salvar(form).then((response) => {
        this.requisitandoSenha = false
      }).catch(() => {
        this.requisitandoSenha = false
      })
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    cadastrarRedeSocial (dados = null) {
      if (dados) {
        let senhaGerada = Math.random().toString(36).substring(2)

        this.form.nome = dados.nome
        this.form.email = dados.email
        this.form.senha = senhaGerada
        this.form.senha_confirmation = senhaGerada

        if (dados.google_id) {
          this.form.google_id = dados.google_id
        } else {
          delete this.form.google_id
        }

        if (dados.facebook_id) {
          this.form.facebook_id = dados.facebook_id
        } else {
          delete this.form.facebook_id
        }

        if (this.form.documento && this.form.documento.length > 0 && this.form.celular && this.form.celular.length > 0) {
          this.cadastrar()
        } else {
          this.notificacao('aviso', 'Informe os dados restantes e depois clique em cadastrar!')
          this.form.documento = null
          this.form.celular = null
        }
      }
    },

    // Login GOOGLE
    onSuccessLoginGoogle (googleUser) {
      const aviso = this.notificacao('enviando')
      this.enviando = true

      var profile = googleUser.getBasicProfile()
      var dadosGoogle = {
        google_id: profile.getId(),
        nome: profile.getName(),
        foto: profile.getImageUrl(),
        email: profile.getEmail()
      }

      this.usuarioModel.loginRedeSocial('google', dadosGoogle.google_id, dadosGoogle).then((res) => {
        aviso()
        this.salvando = false

        const retornoLaravel = res ? res.data : null

        if ([500, 400].indexOf(res.status) !== -1 || res.data.erro) {
          this.cadastrarRedeSocial(dadosGoogle)
        }

        // USUARIO GOOGLE JA CADASTRADO NO SISTEMA
        if ([200].indexOf(res.status) !== -1 && res.data.erro === 0) {
          const notify = this.notificacao('salvo', 'Bem vindo!')
          this.$store.dispatch('usuario-login/setLogado', retornoLaravel.dados)
          LocalStorage.set('lembrarLogin', this.lembrar)
          axios.defaults.headers.common.Authorization = 'Bearer ' + retornoLaravel.dados.token

          LocalStorage.remove('bitrixdadoscadastro')

          setTimeout(() => notify(), 3000)
          if (this.passosCredere) {
            this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id, publico: this.passosCredere.publico } })
          } else {
            this.irRota({ name: 'painel-inicio' })
          }
        }
        /* */
      }).catch(error => {
        aviso()
        this.notificacao('erro', error.msg)
      })
    },

    onFailureLoginGoogle (onFailure) {
      console.error('onFailureLoginGoogle:', onFailure)
      this.notificacao('erro', `Google Login Error: ${JSON.stringify(onFailure)}`)
    },
    /* */

    // Login FACEBOOK
    loginSuccessFacebook (response) {
      const aviso = this.notificacao('enviando')
      this.enviando = true

      /* eslint-disable */
      FB.api('/me?fields=id,email,name', res => {
        var dadosFacebook = {
          facebook_id: res.id,
          nome: res.name,
          email: res.email
        }

        this.usuarioModel.loginRedeSocial('facebook', dadosFacebook.facebook_id, dadosFacebook).then((res) => {
          aviso()
          const retornoLaravel = res ? res.data : null

          if ([500, 400].indexOf(res.status) !== -1 || res.data.erro) {
            this.cadastrarRedeSocial(dadosFacebook)
          }

          // USUARIO FACEBOOK JA CADASTRADO NO SISTEMA
          if ([200].indexOf(res.status) !== -1 && res.data.erro === 0) {
            const notify = this.notificacao('salvo', 'Bem vindo!')
            this.$store.dispatch('usuario-login/setLogado', retornoLaravel.dados)
            LocalStorage.set('lembrarLogin', this.lembrar)
            axios.defaults.headers.common.Authorization = 'Bearer ' + retornoLaravel.dados.token

            LocalStorage.remove('bitrixdadoscadastro')

            setTimeout(() => notify(), 3000)
            if (this.passosCredere) {
              this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id, publico: this.passosCredere.publico } })
            } else {
              this.irRota({ name: 'painel-inicio' })
            }
          }
          /* */
        }).catch(error => {
          aviso()
          this.notificacao('erro', error.msg)
        })
      })
    },

    loginErrorFacebook (error) {
      console.error('onSignInError', error)
      this.notificacao('erro', `Facebook Login Error: ${JSON.stringify(error)}`)
    },
    /* fim - Login FACEBOOK */

    irRota (rota) {
      this.$router.push(rota)
      // let routeData = this.$router.resolve(rota)
      // window.location.href = routeData.href
    }
  }
}
