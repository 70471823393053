import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Beneficios',
  components: { VueSlickCarousel },
  props: {
    principal: {
      type: Boolean,
      default: false
    },
    beneficios: {
      type: Array,
      default: () => {
        return [
          {
            img: 'app:home_1',
            titulo: '10 dias de Test Drive',
            descricao: 'Confiamos tanto em nossos carros, que te damos 10 dias para você ter certeza da compra. Caso não tenha, pode devolver.'
          },
          {
            img: 'app:home_3',
            titulo: 'Entregamos em casa!',
            descricao: 'Nossos clientes não saem de casa. Em primeiro lugar o conforto. Por isso, levamos o carro até sua casa. Praticidade e transparência. Assim é fácil!'
          },
          {
            img: 'app:home_2',
            titulo: '1 ano de garantia!',
            descricao: '100 dias de garantia Total e 1 ano para motor e câmbio. Por isso, nossos carros são os mais confiáveis do mercado. Assim é fácil!'
          },
          {
            img: 'app:home_4',
            titulo: 'Melhores financiamentos',
            descricao: 'Não trabalhamos com intermediários, temos integrações diretas com os bancos. Por isso, as melhoras taxas estão aqui.'
          }
        ]
      }
    }
  },
  data () {
    return {
      carrosselCards: {
        lazyLoad: 'ondemand',
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        responsive: [
          {
            breakpoint: 2400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 3.2,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1020,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 915,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  }
}
