import PadraoModel from './PadraoModel'
import axios from 'axios'
// import store from '../store/index'

export default class UsuarioModel extends PadraoModel {
  uri = '/usuarios';
  microServico = 'usuario';
  storeName = 'usuarios';
  botaoTopo = {
    grid: {
      texto: 'Cadastrar',
      routeName: this.routeName
    },
    form: {
      texto: 'Listar',
      routeName: this.routeName + 'Grid'
    }
  };

  cabecalho = {
    grid: {
      titulo: 'Lista de Usuários',
      preTitulo: 'Todos os Usuários',
      mostrar: true
    },
    form: {
      titulo: 'Cadastro de Usuários',
      preTitulo: 'Usuários',
      mostrar: true
    }
  };

  async login (params = null) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.API.usuario + '/auth/login', params).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async login2 (params = null) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.API.usuario + '/auth/login?include=roles,documento_lead', params).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async cadastrar (params = null) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.API.usuario + '/usuarios', params).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        const erros = Object.keys(error.response.data.dados).reduce((acc, erro) => {
          let errosSemCampos = [...error.response.data.dados[erro]]
          errosSemCampos = errosSemCampos.map(erroAtual => `[${erro}] - ${erroAtual}`)
          acc = acc.concat([...errosSemCampos])
          return acc
        }, [])
        reject(erros)
      })
    })
  }

  async loginRedeSocial (tipo, id, params) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.API.usuario + `/auth/${tipo}/${id}?include=roles`, params).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async loginNode (params = null) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.API.fire + '/verificar-login', params).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async alterarSenhaNode (params = null) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.API.fire + '/update-senha', params).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async salvarToken (params = null) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.API.fire + '/salvar-token', params).then((response) => {
        resolve(response.data)
        axios.post(process.env.API.usuario + '/auth/login?include=roles', params).then((response) => {
          resolve(this.transformDipashUserData(response.data))
        })
      })
    })
  }

  /**
   * Normaliza os dados do usuario para armazenamento no localstorage
   * @param {*} response
   */
  transformDipashUserData (response) {
    if (response.dados && response.dados.GLO_NOME) {
      let data = {}
      if (response.dados.GLO_NOME) {
        data = {
          nome: response.dados.GLO_NOME,
          email: response.dados.GLO_EMAIL,
          token: response.dados.token,
          id: response.dados.USU_ID
        }
      } else {
        data = {
          nome: response.dados.global.pessoa_fisica.PFI_RAZAO_SOCIAL,
          email: response.dados.GLO_EMAIL,
          token: response.dados.token,
          id: response.dados.USU_ID
        }
      }
      Object.assign(response.dados, data)
    }
    return response
  }

  async buscar (params = null) {

  }
}
