export default function () {
  return {
    dados: {
      'tipos-servicos': [], // nome do recurso no model
      'posicao-banners': [],
      'grupos-categorias': [],
      'categorias': [],
      'carrocerias': [],
      'cores': [],
      'combustiveis': [],
      'cambios': [],
      'acessorios': [],
      'grupos-acessorios': [],
      'grupos-verificacoes': [],
      'situacoes-laudos': [],
      'verificacoes': [],
      'locais': [],
      'tags': [],
      'bens': [],
      'anuncios': [],
      'estoque': [],
      'versoes': [],
      'modelos': [],
      'marcas': [],
      'laudos': [],
      'midias': [],
      clientes: [],
      carteiras: [],
      conteudos: [],
      banners: [],
      propostas: [],
      testeDrive: [],
      delivery: [],
      videochamada: [],
      'credere/propostas': [],
      ofertas: []
    },
    filtros: {
      'tipos-servicos': [],
      'posicao-banners': [],
      'grupos-categorias': [],
      'categorias': [],
      'carrocerias': [],
      'cores': [],
      'combustiveis': [],
      'cambios': [],
      'acessorios': [],
      'grupos-acessorios': [],
      'grupos-verificacoes': [],
      'situacoes-laudos': [],
      'verificacoes': [],
      'locais': [],
      'tags': [],
      'bens': [],
      'anuncios': [],
      'estoque': [],
      'versoes': [],
      'modelos': [],
      'marcas': [],
      'laudos': [],
      'midias': [],
      clientes: [],
      carteiras: [],
      conteudos: [],
      banners: [],
      'credere/propostas': [],
      ofertas: []
    },
    pagination: {
      'tipos-servicos': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'posicao-banners': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'grupos-categorias': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'categorias': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'carrocerias': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'cores': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'combustiveis': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'cambios': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'acessorios': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'grupos-acessorios': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'grupos-verificacoes': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'situacoes-laudos': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'verificacoes': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'locais': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'midias': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'tags': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'bens': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'anuncios': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'estoque': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'versoes': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'modelos': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'marcas': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'laudos': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      clientes: { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      carteiras: { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      conteudos: { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      banners: { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      'credere/propostas': { sortBy: 'id', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 },
      ofertas: { sortBy: 'OFE_ID', descending: true, page: 1, rowsPerPage: 10, rowsNumber: 0 }
    },
    dialogAuxiliar: {
      mostrar: false,
      dadosExtras: {}
    }
  }
}
