import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import DpGridPageMixin from 'src/core/components/DpGrid/DpGridPageMixin'
import { date, extend, openURL, clone, LocalStorage } from 'quasar'
import DpGridStore from 'src/store/dp-grid/'
import vTopFilters from 'src/core/filters/vTopFilters'
// import axios from 'axios'
// import localStore from 'src/store/local-com-estoque/'
// import excelStore from 'src/store/importacao-excel/'

export default {
  name: 'DpGrid',
  mixins: [NotificacaoMixin, DpGridPageMixin, vTopFilters],
  props: {
    modelUtilizadoGrid: { type: Object, required: true }, // qual model padrao para trabalhar
    interna: { type: Boolean, default: false }, // se é uma grid interna
    mostrarTitulo: { type: Boolean, default: true },
    mostrarRecurso: { type: Boolean, default: true },
    prefixoLista: { type: String, default: ' de ' },
    importarExcel: { type: Boolean, default: false },
    exportarExcel: { type: Boolean, default: false },

    // storePadrao: { type: String, required: true }, // qual store ira trabalhar
    mostrarBotaoNovo: { type: Boolean, default: true }, // mostrar o botão novo padrao
    buscarAoIniciar: { type: Boolean, default: true }, // nao executar a acao pullPadrao ao cadastrar
    // searchFixo: { type: String, default: null }, // passar o marca_id:1 por exemplo
    // interna: { type: Boolean, default: false }, // não irá mudar a rota e nem ler rota
    ocultarTitulo: { type: Boolean, default: false },
    mostrarSeletorColunas: { type: Boolean, default: true },
    // exportarXls: { type: Boolean, default: false },
    // importarExcel: { type: Boolean, default: false },
    removerColuna: { type: Array, default: () => [] },
    // mostrarTodosLocais: { type: Boolean, default: false },
    // esconderResultados: { type: Boolean, default: false },
    virtualScroll: { type: Boolean, default: false },
    somentePaginacao: { type: Boolean, default: false },
    debug: { type: Boolean, default: false },
    dialogApagar: { type: Boolean, default: false },
    customClass: { type: String, default: '' },
    hideTop: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false }
    // buscaCustomizada: { type: Boolean, default: false },
    // idFixo: { type: Number, default: null },
    // prefixoLista: { type: String, default: ' de ' }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
    // if (!this.$store.hasModule('excelImportacao')) this.$store.registerModule('excelImportacao', excelStore)
    // if (!this.$store.hasModule('local-com-estoque')) this.$store.registerModule('local-com-estoque', localStore)
  },
  meta () {
    return {
      title: this.mostrarTitulo ? 'Lista' + this.prefixoLista : ''
    }
  },
  data () {
    return {
      dadosGrid: [], // valores da grid
      condicao: '', // maior menor etc
      tipoCondicaoAtual: null, // texto, inteiro, decimal
      valor: null, // valor para ser inserido no filtro input e select
      modelData: null, // valor para tipo date time
      modelDataSimples: null, // valor para tipo date
      tentouEnviar: false, // se tentou enviar validate manual
      opcoesSelect: ['Sim', 'Não'], // opcoes para select
      carregandoBusca: false,
      // antigo
      filtroSelecionado: false,
      drawerRight: false,
      editarColunas: false,
      perguntarComitente: false,
      arquivoExcel: null,
      modeloExcelModal: false,
      condicao_tipos: {
        texto: ['contendo', 'não contendo'],
        inteiro: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente'],
        decimal: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente'],
        boleano: ['Igual'],
        boleanoSim: ['Igual'],
        e: ['é', 'não é'],
        data: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente'],
        dataHora: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente'],
        dataHoraMinuto: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente'],
        hora: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente'],
        horaMinuto: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente'],
        horaMinutoSegundo: ['Igual', 'Maior', 'Menor', 'Maior ou igual', 'Menor ou igual', 'Diferente']
      },
      moneyFormatForComponent: {
        thousands: '.',
        decimal: ',',
        // prefix: 'R$ ',
        precision: 2,
        masked: true
      },
      visibleColumns: [],
      local: null,
      filtroLocais: '',
      resultadoLocais: []
    }
  },
  watch: {

  },
  computed: {
    filtrosComputed: {
      get () {
        return this.$store.getters['dp-grid/getFiltros'][this.modelUtilizadoGrid.recurso] ?? this.notificacao('erro', 'Atenção criar a state.dp-grid.filtros.<b><u>' + this.modelUtilizadoGrid.recurso + '</u></b>', 1000000)
      }
    },
    dialogAuxiliarMostrarComputed: {
      get () {
        return this.$store.getters['dp-grid/getDialogAuxiliarMostrar']
      },
      set (value) {
        this.$store.commit('dp-grid/putDialogAuxiliarMostrar', value)
      }
    },
    filtrosTratadosComputed: {
      get () {
        let filtrox = []
        if (this.filtrosComputed) {
          const colunasGrid2 = [Object.values(extend(true, {}, this.modelUtilizadoGrid.colunasGrid))]
          const filtrosTmp = [Object.values(extend(true, {}, this.filtrosComputed))]
          if (filtrosTmp.length > 0) {
            // console.warn('filtros', filtrosTmp[0])
            // console.warn('colunasGrid2', colunasGrid2[0])
            filtrox = filtrosTmp[0].map((elem, index, arr) => {
              const buscaField = colunasGrid2[0].filter((item, key) => {
                if (item.name === elem.campo) { return item }
              })
              // console.warn('buscaField', buscaField)
              if (buscaField.length > 0) { elem.campo = buscaField[0].field }

              return {
                ['condicao' + index]: elem.condicao.toLowerCase(),
                ['campo' + index]: elem.campo,
                ['valor' + index]: elem.valor
              }
            })
            // console.warn('filtroxxx', filtrox)
          }
        }
        return filtrox
      }
    },
    paginationComputed: {
      get () {
        return this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso] ?? this.notificacao('erro', 'Atenção criar a state.dp-grid.pagination.<b><u>' + this.modelUtilizadoGrid.recurso + '</u></b>', 1000000)
      },
      set (value) {

      }
    },
    pageComputed: {
      get () {
        return this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso] ? parseInt(this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso].page) : 0
      },
      set (value) {
        this.$store.commit('dp-grid/putCampoPagination', { recurso: this.modelUtilizadoGrid.recurso, campo: 'page', value })
      }
    },
    totalRegistrosComputed: {
      get () {
        return this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso] ? this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso].rowsNumber : 0
      },
      set (value) {

      }
    },
    registrosPorPaginaComputed: {
      get () {
        return this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso] ? this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso].rowsPerPage : 0
      },
      set (value) {
        this.$store.commit('dp-grid/putCampoPagination', { recurso: this.modelUtilizadoGrid.recurso, campo: 'rowsPerPage', value: value })
        this.$store.commit('dp-grid/putCampoPagination', { recurso: this.modelUtilizadoGrid.recurso, campo: 'page', value: 1 })
      }
    },
    maxPageComputed: {
      get () {
        return this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso] ? Math.ceil(this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso].rowsNumber / this.$store.getters['dp-grid/getPagination'][this.modelUtilizadoGrid.recurso].rowsPerPage) : 0
      },
      set (value) {

      }
    },
    dadosComputed: {
      get () {
        return this.$store.getters['dp-grid/getDados'][this.modelUtilizadoGrid.recurso] ?? this.notificacao('erro', 'Atenção criar a state.dp-grid.dados.<b><u>' + this.modelUtilizadoGrid.recurso + '</u></b>', 1000000)
      }
    },
    campoParaFiltro () {
      // atencao :D mapear também na funcao addFiltro la em baixo os tipos pra pegar o "value"/model :D

      if (['dataHora', 'dataHoraMinuto'].indexOf(this.tipoCondicaoAtual) >= 0) {
        return 'dataHora'
      } else if (this.tipoCondicaoAtual === 'data') {
        return 'data'
      } else if (this.tipoCondicaoAtual === 'boleanoSim' || this.tipoCondicaoAtual === 'boleano') {
        this.opcoesSelect = ['Sim', 'Não']
        return 'select'
      } else if (this.tipoCondicaoAtual === 'decimal') {
        return 'inputDecimal'
      } else {
        return 'inputPadrao'
      }
    },
    valorIsValid () {
      if (this.valor === null && this.tentouEnviar === true) {
        return false
      }
      return true
    },
    dataEnvioIsValid () {
      if (this.modelData === null && this.tentouEnviar === true) {
        return false
      }
      return true
    },
    dataEnvioSimplesIsValid () {
      if (this.modelDataSimples === null && this.tentouEnviar === true) {
        return false
      }
      return true
    },
    formattedDateDataExibicao: {
      get () {
        // return date.formatDate(this.modelData, 'DD/MM/YYYY HH:mm')
        return this.$options.filters.dateBrSemSegundos(this.modelData)
      },
      set (value) {
        // temporario
        let data = value
        let trocar = data.split('/')
        const aux = trocar[0]
        trocar[0] = trocar[1]
        trocar[1] = aux
        trocar = trocar.join('/')
        data = date.formatDate(trocar, 'YYYY-MM-DD HH:mm:ss')
        this.$emit('input', data || null)
        // this.$store.commit(this.storePadrao + '/putCampoForm', { campo: 'data_inicio_exibicao', value: data })
        this.modelData = data
      }
    },
    formattedDateDataSimplesExibicao: {
      get () {
        return date.formatDate(this.modelDataSimples, 'DD/MM/YYYY')
      },
      set (value) {
        // temporario
        let data = value
        let trocar = data.split('/')
        const aux = trocar[0]
        trocar[0] = trocar[1]
        trocar[1] = aux
        trocar = trocar.join('/')
        data = date.formatDate(trocar, 'YYYY-MM-DD')
        this.$emit('input', data || null)
        // this.$store.commit(this.storePadrao + '/putCampoForm', { campo: 'data_inicio_exibicao', value: data })
        this.modelDataSimples = data
      }
    }
  },
  created () {

  },
  mounted () {
    // this.buscarGrid()
    if (this.buscarAoIniciar) this.leituraParamsEBusca()
    this.getColunas()
  },
  methods: {

    leituraParamsEBusca (reset) {
      if (Object.keys(this.$route.query).length > 0 && this.interna === false) {
        let itemBusca = {}
        Object.keys(this.$route.query).map((campo, index) => {
          const valor = this.$route.query[campo]
          if (campo.indexOf('campo') !== -1) {
            itemBusca.campo = valor
          }
          if (campo.indexOf('condicao') !== -1) {
            // indice = valor.replace('campo', '')
            itemBusca.condicao = valor
          }
          if (campo.indexOf('label') !== -1) {
            // indice = valor.replace('campo', '')
            itemBusca.label = valor
          }
          if (campo.indexOf('valor') !== -1) {
            // indice = valor.replace('campo', '')
            itemBusca.valor = valor
            this.$store.commit('dp-grid/putNovoFiltro', { recurso: this.modelUtilizadoGrid.recurso, payload: itemBusca })
            itemBusca = {}
          }
          if (campo === 'pagina') {
            this.$store.commit('dp-grid/putCampoPagination', { recurso: this.modelUtilizadoGrid.recurso, campo: 'page', value: valor })
          }
          if (campo === 'decrescente') {
            this.$store.commit('dp-grid/putCampoPagination', { recurso: this.modelUtilizadoGrid.recurso, campo: 'descending', value: valor })
          }
          if (campo === 'ordenarPor') {
            this.$store.commit('dp-grid/putCampoPagination', { recurso: this.modelUtilizadoGrid.recurso, campo: 'sortBy', value: valor })
          }
          if (campo === 'porPagina') {
            this.$store.commit('dp-grid/putCampoPagination', { recurso: this.modelUtilizadoGrid.recurso, campo: 'rowsPerPage', value: valor })
          }
          // aguardar o ultimo para chamar a action
          if ((index + 1) === Object.keys(this.$route.query).length) {
            this.buscarGrid({ local: 'leituraParamsEBusca' })
          }
        })
      } else {
        this.buscarGrid({ local: 'leituraParamsEBusca' })
      }
    },
    onAtualizarGrid (props, pageSet = null) {
      let { page, rowsPerPage, sortBy, descending } = props.pagination
      const filter = props.filter
      if (pageSet) { page = pageSet }

      this.buscarGrid({ local: 'onAtualizarGrid', qtable: { page: page, rowsPerPage: rowsPerPage, filter, sortBy, descending } })
    },
    clickPagination (props) {
      this.pageComputed = props
      if (this.interna === false) { this.$router.push({ query: this.getFiltrosParaObjetoURL() }) }
      this.onAtualizarGrid({ pagination: this.paginationComputed, filter: {} }, props)
    },
    buscarGrid ({ qtable, local, push, semInclude, exportar }) {
      let sortByBug = this.paginationComputed.sortBy
      let descendingBug = this.paginationComputed.descending
      let orderByCustomizado = null
      let sortedByCustomizado = null
      if (qtable) {
        sortByBug = qtable.sortBy
        descendingBug = qtable.descending
      }
      const coluna = this.modelUtilizadoGrid.colunasGrid.filter(elem => elem.name === sortByBug)
      if (coluna.length > 0) {
        if (typeof coluna[0].orderByCustomizado !== 'undefined') {
          orderByCustomizado = coluna[0].orderByCustomizado
          sortedByCustomizado = descendingBug
        }
      }

      let parametrosApiGet = {
        search: this.modelUtilizadoGrid.searchFixo,
        page: this.paginationComputed.page,
        porPagina: this.paginationComputed.rowsPerPage,
        searchJoin: 'and',
        paginaUltimaSemFiltro: 1,
        orderBy: sortByBug,
        sortedBy: descendingBug ? 'desc' : 'asc',
        orderByCustomizado: orderByCustomizado,
        sortedByCustomizado2: sortedByCustomizado,
        sortedByCustomizado: descendingBug ? 'desc' : 'asc',
        limit: this.paginationComputed.rowsPerPage,
        include: semInclude ? null : this.modelUtilizadoGrid.include
      }

      if (exportar) {
        parametrosApiGet.exportar = exportar
      }
      const v2 = this.filtrosTratadosComputed
      v2.map((arr, index) => {
        parametrosApiGet = { ...parametrosApiGet, ...arr }
      })
      this.carregandoBusca = true

      this.modelUtilizadoGrid.getListagem({ params: parametrosApiGet, acao: this.modelUtilizadoGrid.acao }).then((retorno) => {
        if (exportar) {
          this.carregandoBusca = false
          openURL(retorno.dados.arquivo_url)
          return
        }
        this.carregandoBusca = false
        // pra que isso ?
        // if (push) {
        //   const dados = clone(getters.getDados)
        //   retorno.dados.map((ele) => dados.push(ele))
        //   commit('putDados', dados)
        // }

        this.$store.commit('dp-grid/putDados', { recurso: this.modelUtilizadoGrid.recurso, payload: retorno.dados ?? [] })
        this.$store.commit('dp-grid/putPagination', {
          recurso: this.modelUtilizadoGrid.recurso,
          payload: {
            sortBy: sortByBug,
            descending: descendingBug,
            page: retorno.meta.pagination.current_page,
            rowsPerPage: retorno.meta.pagination.per_page,
            rowsNumber: retorno.meta.pagination.total
          }
        })
      }).catch(error => {
        this.notificacao('erro', 'Erro na comunicação com o servidor!')
        console.error(error)
      })
    },
    irParaNovoInterno (evt) {
      if (this.existeAcaoBotaoNovoListener !== undefined) {
        this.$emit('acaoBotaoNovo')
      } else { this.$router.push({ name: (this.modelUtilizadoGrid.recurso + '.form') }) }
    },
    abrirOpcaoFiltro (tipoCondicao) {
      // limpando para não pegar opcões anteriores
      this.tentouEnviar = false
      this.tipoCondicaoAtual = tipoCondicao
      if (tipoCondicao === 'decimal') {
        this.valor = 0
      } else {
        this.valor = null
      }
      this.condicao = this.condicao_tipos[tipoCondicao][0]
    },
    addFiltro (campo, label) {
      let valorParaAdd = null
      if (this.campoParaFiltro === 'dataHora') {
        // valorParaAdd = this.modelData
        valorParaAdd = this.$options.filters.dateBrSemSegundos(this.modelData)
        // console.warn(this.formattedDateDataSimplesExibicao())
      }
      if (this.campoParaFiltro === 'data') {
        // valorParaAdd = this.modelDataSimples
        // console.log('this.modelDataSimples', this.modelDataSimples) adicionado 6 horas pra garantir q ia para proximo dia pelo date
        valorParaAdd = this.$options.filters.dateBrSimples(this.modelDataSimples + ' 06:00:00')
      }
      if (['inputPadrao', 'select', 'inputDecimal'].indexOf(this.campoParaFiltro) >= 0) {
        valorParaAdd = this.valor
      }
      if (valorParaAdd === null) {
        this.tentouEnviar = true
        return false
      }
      // console.warn('ue')
      this.$store.commit('dp-grid/putNovoFiltro', { recurso: this.modelUtilizadoGrid.recurso, payload: { label: label, condicao: this.condicao, valor: valorParaAdd, campo: campo } })
      this.buscarGrid({ local: 'addFiltro' })
      // this.$store.dispatch(this.storePadrao + '/pullPadrao', { token: this.token, local: 'addFiltro' })
      // this.valor = null
      if (this.interna === false) { this.$router.push({ query: this.getFiltrosParaObjetoURL() }) }
      // return true
    },
    limparFiltros () {
      this.$store.commit('dp-grid/limparFiltros', { recurso: this.modelUtilizadoGrid.recurso })
      this.buscarGrid({ local: 'addFiltro' })
      if (this.interna === false) { this.$router.push({ query: this.getFiltrosParaObjetoURL() }) }
      // this.buscarGrid()
      // this.$store.dispatch(this.storePadrao + '/pullPadrao', { token: this.token, local: 'removerFiltro' })
    },
    removerFiltro (index) {
      this.$store.commit('dp-grid/putRemoverFiltro', { recurso: this.modelUtilizadoGrid.recurso, index })
      this.buscarGrid({ local: 'addFiltro' })
      if (this.interna === false) { this.$router.push({ query: this.getFiltrosParaObjetoURL() }) }
      // this.$store.dispatch(this.storePadrao + '/pullPadrao', { token: this.token, local: 'removerFiltro' })
    },
    getFiltrosParaObjetoURL () {
      // return state.filtros.reduce((obj, index, item) => Object.assign(obj, { [item.campo]: item.valor }), {})\
      // console.warn('state.recursoAtual', state.recursoAtual)
      const retorno = {}
      if (this.filtrosComputed) {
        this.filtrosComputed.map((elem, index, completo) => {
          retorno['label' + index] = elem.label
          retorno['campo' + index] = elem.campo
          retorno['condicao' + index] = elem.condicao
          retorno['valor' + index] = elem.valor
        })
        // console.log('aae')
        retorno.pagina = this.paginationComputed.page
        retorno.porPagina = this.paginationComputed.rowsPerPage
        retorno.ordenarPor = this.paginationComputed.sortBy
        retorno.decrescente = this.paginationComputed.descending
        retorno.rand = Math.random()
      }
      return retorno
    },
    confirmarApagarLinha () {
      // const id = this.$store.getters['padrao/getIdDelete']
      const notificao = this.notificacao('enviando', 'Apagando registro...')
      const id = this.$store.getters['dp-grid/getDialogAuxiliar'].dadosExtras.id
      this.$store.getters['dp-grid/getDialogAuxiliar'].dadosExtras.model.deletar(id).then((retorno) => {
        notificao()
        const novoGrid = clone(this.$store.getters['dp-grid/getDados'][this.$store.getters['dp-grid/getDialogAuxiliar'].dadosExtras.model.recurso])
        if (novoGrid !== undefined) {
          const filtrado = novoGrid.filter((item) => {
            if (item.id !== this.$store.getters['dp-grid/getDialogAuxiliar'].dadosExtras.model.form.id) { return true }
          })
          this.$store.commit('dp-grid/putDados', { recurso: this.$store.getters['dp-grid/getDialogAuxiliar'].dadosExtras.model.recurso, payload: filtrado })
        }
        this.notificacao('salvo', 'Registro apagado com sucesso!', 2000)
        this.buscarGrid({ local: 'confirmarApagar' })
      }).catch(error => {
        this.notificacao('erro', 'Erro na comunicação com o servidor!')
        console.error(error)
      })
    },
    getColunas () {
      this.modelUtilizadoGrid.colunasGrid.map((item, key) => {
        this.visibleColumns = this.visibleColumns.concat(item.name)
        if (LocalStorage.has(`${this.modelUtilizadoGrid.recurso}-colunas`)) {
          this.visibleColumns = JSON.parse(LocalStorage.getItem(`${this.modelUtilizadoGrid.recurso}-colunas`))
        }
        if (this.condicao_tipos[item.buscaTipo]) { this.condicao = this.condicao_tipos[item.buscaTipo][0] }
      })

      this.removerColuna.map((name) => {
        this.visibleColumns = this.visibleColumns.filter(ele => ele !== name)
      })
    }
  }
}
