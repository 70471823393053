import PadraoModel from './../../core/model/PadraoModel'
import axios from 'axios'

export default class ElasticSearchModel extends PadraoModel {
  uri = '/elastic';
  microServico = 'usuario';
  storeName = 'usuarios';
  botaoTopo = {
    grid: {
      texto: 'Cadastrar',
      routeName: this.routeName
    },
    form: {
      texto: 'Listar',
      routeName: this.routeName + 'Grid'
    }
  }

  busca = {
    size: 14,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              must: [],
              must_not: [{
                exists: {
                  field: 'deleted_at'
                }
              },
              {
                match: {
                  situacao_id: 9
                }
              },
              {
                match: {
                  situacao_id: 20
                }
              }
              ],
              should: [
                {
                  bool: {
                    must: []
                  }
                }
              ]
            }
          }
        ]
      }
    },
    aggs: {
      faixa_parcela: {
        range: {
          field: 'valor_parcelas',
          ranges: [
            { to: 1500 },
            { from: 1500, to: 2000 },
            { from: 2000, to: 2500 },
            { from: 2500, to: 3000 },
            { from: 3000, to: 3500 },
            { from: 3500, to: 4000 },
            { from: 4000 }
          ]
        }
      },
      faixa_valor: {
        range: {
          field: 'valor_venda_pf',
          ranges: [
            { to: 100000 },
            { from: 100000, to: 200000 },
            { from: 200000, to: 300000 },
            { from: 300000, to: 400000 },
            { from: 400000, to: 500000 },
            { from: 500000, to: 600000 },
            { from: 600000, to: 700000 },
            { from: 700000, to: 800000 },
            { from: 800000, to: 900000 },
            { from: 900000 }
          ]
        }
      },
      faixa_km: {
        range: {
          field: 'km',
          ranges: [
            { to: 15000 },
            { from: 15000, to: 25000 },
            { from: 25000, to: 50000 },
            { from: 50000, to: 100000 },
            { from: 100000 }
          ]
        }
      },
      menor_ano_fabricacao: {
        min: {
          field: 'ano_fabricacao'
        }
      },
      maior_ano_fabricacao: {
        max: {
          field: 'ano_fabricacao'
        }
      },
      menor_ano_modelo: {
        min: {
          field: 'ano_modelo'
        }
      },
      maior_ano_modelo: {
        max: {
          field: 'ano_modelo'
        }
      },
      menor_valor_atual: {
        min: {
          field: 'valor_atual'
        }
      },
      maior_valor_atual: {
        max: {
          field: 'valor_atual'
        }
      },
      menor_valor_venda_pf: {
        min: {
          field: 'valor_venda_pf'
        }
      },
      maior_valor_venda_pf: {
        max: {
          field: 'valor_venda_pf'
        }
      },
      menor_valor_venda_pcd: {
        min: {
          field: 'valor_venda_pcd'
        }
      },
      maior_valor_venda_pcd: {
        max: {
          field: 'valor_venda_pcd'
        }
      },
      menor_valor_venda_pj: {
        min: {
          field: 'valor_venda_pj'
        }
      },
      maior_valor_venda_pj: {
        max: {
          field: 'valor_venda_pj'
        }
      },
      menor_valor_parcela: {
        min: {
          field: 'valor_parcelas'
        }
      },
      maior_valor_parcela: {
        max: {
          field: 'valor_parcelas'
        }
      },
      menor_km: {
        min: {
          field: 'km'
        }
      },
      maior_km: {
        max: {
          field: 'km'
        }
      },
      menor_inicial_publico: {
        min: {
          field: 'valor_inicial_publico'
        }
      },
      maior_inicial_publico: {
        max: {
          field: 'valor_inicial_publico'
        }
      },
      menor_valor_venda: {
        min: {
          field: 'valor_venda'
        }
      },
      maior_valor_venda: {
        max: {
          field: 'valor_venda'
        }
      },
      menor_valor_minimo: {
        min: {
          field: 'valor_minimo'
        }
      },
      maior_valor_minimo: {
        max: {
          field: 'valor_minimo'
        }
      },
      tags: {
        terms: {
          field: 'tags_busca.keyword'
        }
      },
      tags_nome: {
        terms: {
          field: 'tags_busca_nome.keyword'
        }
      },
      cor: {
        terms: {
          field: 'cor_objeto.cor_nome.keyword',
          size: 300
        }
      },
      carrocerias: {
        terms: {
          field: 'carroceria_objeto.carroceria_id.keyword'
        }
      },
      carrocerias_nome: {
        terms: {
          field: 'carroceria_objeto.carroceria_nome.keyword'
        }
      },
      categorias_nome: {
        terms: {
          field: 'categoria_objeto.categoria_nome.keyword',
          size: 300
        }
      },
      cambios: {
        terms: {
          field: 'cambio_objeto.cambio_nome.keyword'
        }
      },
      segmentos: {
        terms: {
          field: 'segmento.id.keyword'
        }
      },
      segmentos_nome: {
        terms: {
          field: 'segmento.nome.keyword'
        }
      },
      categorias: {
        terms: {
          field: 'categoria_objeto.categoria_id.keyword'
        }
      },
      combustiveis: {
        terms: {
          field: 'combustivel_objeto.combustivel_nome.keyword'
        }
      },
      caracteristicas: {
        terms: {
          field: 'caracteristicas_busca.keyword',
          size: 300
        }
      },
      marcas: {
        terms: {
          field: 'marca_objeto.marca_id.keyword',
          size: 300
        }
      },
      marcas_nome: {
        terms: {
          field: 'marca_objeto.marca_nome.keyword',
          size: 300
        }
      },
      modelo: {
        terms: {
          field: 'modelo_objeto.modelo_id.keyword',
          size: 300
        }
      },
      modelo_nome: {
        terms: {
          field: 'modelo_objeto.modelo_nome.keyword',
          size: 300
        }
      },
      versao: {
        terms: {
          field: 'versao_objeto.versao_id.keyword',
          size: 300
        }
      },
      versao_nome: {
        terms: {
          field: 'versao_objeto.versao_nome.keyword',
          size: 300
        }
      },
      marca_modelo_versao: {
        terms: {
          field: 'marca_modelo_versao.keyword',
          size: 300
        }
      },
      acessorios: {
        terms: {
          field: 'acessorios_busca_nome.keyword',
          size: 300
        }
      },
      evento: {
        terms: {
          field: 'evento_id'
        }
      },
      evento_nome: {
        terms: {
          field: 'evento_nome.keyword'
        }
      },
      proprietario: {
        terms: {
          field: 'proprietario_local.id.keyword',
          size: 30
        }
      },
      proprietario_nome: {
        terms: {
          field: 'proprietario_local.nome.keyword',
          size: 30
        }
      },
      proprietario_razao_social: {
        terms: {
          field: 'proprietario_local.razao_social.keyword',
          size: 30
        }
      },
      estoque_local_cidade: {
        terms: {
          field: 'estoque_local.cidade.keyword'
        }
      },
      estoque_local_razao_social: {
        terms: {
          field: 'estoque_local.razao_social.keyword'
        }
      },
      finais_placa: {
        terms: {
          field: 'final_placa.keyword'
        }
      }
    }
  }
  buscaPossiveis = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              must: [],
              must_not: [{
                exists: {
                  field: 'deleted_at'
                }
              },
              {
                match: {
                  situacao_id: 9
                }
              },
              {
                match: {
                  situacao_id: 20
                }
              }
              ],
              should: []
            }
          }
        ]
      }
    },
    aggs: {
      faixa_parcela: {
        range: {
          field: 'valor_parcelas',
          ranges: [
            { to: 1500 },
            { from: 1500, to: 2000 },
            { from: 2000, to: 2500 },
            { from: 2500, to: 3000 },
            { from: 3000, to: 3500 },
            { from: 3500, to: 4000 },
            { from: 4000 }
          ]
        }
      },
      faixa_valor: {
        range: {
          field: 'valor_venda_pf',
          ranges: [
            { to: 100000 },
            { from: 100000, to: 200000 },
            { from: 200000, to: 300000 },
            { from: 300000, to: 400000 },
            { from: 400000, to: 500000 },
            { from: 500000, to: 600000 },
            { from: 600000, to: 700000 },
            { from: 700000, to: 800000 },
            { from: 800000, to: 900000 },
            { from: 900000 }
          ]
        }
      },
      faixa_km: {
        range: {
          field: 'km',
          ranges: [
            { to: 15000 },
            { from: 15000, to: 25000 },
            { from: 25000, to: 50000 },
            { from: 50000, to: 100000 },
            { from: 100000 }
          ]
        }
      },
      menor_ano_fabricacao: {
        min: {
          field: 'ano_fabricacao'
        }
      },
      maior_ano_fabricacao: {
        max: {
          field: 'ano_fabricacao'
        }
      },
      menor_ano_modelo: {
        min: {
          field: 'ano_modelo'
        }
      },
      maior_ano_modelo: {
        max: {
          field: 'ano_modelo'
        }
      },
      menor_valor_atual: {
        min: {
          field: 'valor_atual'
        }
      },
      maior_valor_atual: {
        max: {
          field: 'valor_atual'
        }
      },
      menor_valor_venda_pf: {
        min: {
          field: 'valor_venda_pf'
        }
      },
      maior_valor_venda_pf: {
        max: {
          field: 'valor_venda_pf'
        }
      },
      menor_valor_venda_pcd: {
        min: {
          field: 'valor_venda_pcd'
        }
      },
      maior_valor_venda_pcd: {
        max: {
          field: 'valor_venda_pcd'
        }
      },
      menor_valor_venda_pj: {
        min: {
          field: 'valor_venda_pj'
        }
      },
      maior_valor_venda_pj: {
        max: {
          field: 'valor_venda_pj'
        }
      },
      menor_valor_parcela: {
        min: {
          field: 'valor_parcelas'
        }
      },
      maior_valor_parcela: {
        max: {
          field: 'valor_parcelas'
        }
      },
      menor_km: {
        min: {
          field: 'km'
        }
      },
      maior_km: {
        max: {
          field: 'km'
        }
      },
      menor_inicial_publico: {
        min: {
          field: 'valor_inicial_publico'
        }
      },
      maior_inicial_publico: {
        max: {
          field: 'valor_inicial_publico'
        }
      },
      menor_valor_venda: {
        min: {
          field: 'valor_venda'
        }
      },
      maior_valor_venda: {
        max: {
          field: 'valor_venda'
        }
      },
      menor_valor_minimo: {
        min: {
          field: 'valor_minimo'
        }
      },
      maior_valor_minimo: {
        max: {
          field: 'valor_minimo'
        }
      },
      tags: {
        terms: {
          field: 'tags_busca.keyword',
          size: 300
        }
      },
      tags_nome: {
        terms: {
          field: 'tags_busca_nome.keyword',
          size: 300
        }
      },
      cambios: {
        terms: {
          field: 'cambio_objeto.cambio_nome.keyword'
        }
      },
      cor: {
        terms: {
          field: 'cor_objeto.cor_nome.keyword',
          size: 300
        }
      },
      carrocerias: {
        terms: {
          field: 'carroceria_objeto.carroceria_nome.keyword',
          size: 300
        }
      },
      carrocerias_nome: {
        terms: {
          field: 'carroceria_objeto.carroceria_nome.keyword'
        }
      },
      categorias_nome: {
        terms: {
          field: 'categoria_objeto.categoria_nome.keyword',
          size: 300
        }
      },
      segmentos: {
        terms: {
          field: 'segmento.id.keyword',
          size: 300
        }
      },
      segmentos_nome: {
        terms: {
          field: 'segmento.nome.keyword',
          size: 300
        }
      },
      categorias: {
        terms: {
          field: 'categoria_objeto.categoria_id.keyword',
          size: 300
        }
      },
      combustiveis: {
        terms: {
          field: 'combustivel_objeto.combustivel_nome.keyword'
        }
      },
      caracteristicas: {
        terms: {
          field: 'caracteristicas_busca.keyword',
          size: 300
        }
      },
      marcas: {
        terms: {
          field: 'marca_objeto.marca_id.keyword',
          size: 300
        }
      },
      marcas_nome: {
        terms: {
          field: 'marca_objeto.marca_nome.keyword',
          size: 300
        }
      },
      modelo: {
        terms: {
          field: 'modelo_objeto.modelo_id.keyword',
          size: 300
        }
      },
      modelo_nome: {
        terms: {
          field: 'modelo_objeto.modelo_nome.keyword',
          size: 300
        }
      },
      versao: {
        terms: {
          field: 'versao_objeto.versao_id.keyword',
          size: 300
        }
      },
      versao_nome: {
        terms: {
          field: 'versao_objeto.versao_nome.keyword',
          size: 300
        }
      },
      marca_modelo_versao: {
        terms: {
          field: 'marca_modelo_versao.keyword',
          size: 3000
        }
      },
      acessorios: {
        terms: {
          field: 'acessorios_busca_nome.keyword',
          size: 300
        }
      },
      evento: {
        terms: {
          field: 'evento_id'
        }
      },
      evento_nome: {
        terms: {
          field: 'evento_nome.keyword'
        }
      },
      proprietario: {
        terms: {
          field: 'proprietario_local.id.keyword',
          size: 300
        }
      },
      proprietario_nome: {
        terms: {
          field: 'proprietario_local.nome.keyword',
          size: 300
        }
      },
      proprietario_razao_social: {
        terms: {
          field: 'proprietario_local.razao_social.keyword',
          size: 30
        }
      },
      estoque_local_cidade: {
        terms: {
          field: 'estoque_local.cidade.keyword'
        }
      },
      finais_placa: {
        terms: {
          field: 'final_placa.keyword'
        }
      }
    }
  }

  cabecalho = {
    grid: {
      titulo: 'Lista de Usuários',
      preTitulo: 'Todos os Usuários',
      mostrar: true
    },
    form: {
      titulo: 'Cadastro de Usuários',
      preTitulo: 'Usuários',
      mostrar: true
    }
  };
  gerarBusca (requisicoesBusca, pagina = 1, quantidadePorPagina = 9) {
    const busca = JSON.parse(JSON.stringify(this.busca))
    busca.size = quantidadePorPagina
    busca.from = busca.size * (pagina - 1)
    requisicoesBusca.forEach((elemento) => {
      if (elemento.tipo === 'contendo') {
        let buscaExistente = busca.query.bool.filter[0].bool.should[0].bool.must.find((elementoAuxiliar) => elementoAuxiliar.bool && elementoAuxiliar.bool.should[0].wildcard && (elementoAuxiliar.bool.should[0].wildcard[elemento.termo] !== undefined))
        if (buscaExistente) {
          buscaExistente.bool.should.push({
            wildcard: {
              [elemento.termo]: {
                value: '*' + elemento.valor.toUpperCase() + '*'
              }
            }
          })
          buscaExistente.bool.should.push({ match: { [elemento.termo]: elemento.valor } })
        } else {
          busca.query.bool.filter[0].bool.should[0].bool.must.push({ bool: { should: [{
            wildcard: {
              [elemento.termo]: {
                value: '*' + elemento.valor.toUpperCase() + '*'
              }
            }
          }] } })
        }
      } else if (elemento.tipo === 'multiplo') {
        let buscaExistente = busca.query.bool.filter[0].bool.should[0].bool.must.find((elementoAuxiliar) => elementoAuxiliar.bool && elementoAuxiliar.bool.should[0].match && (elementoAuxiliar.bool.should[0].match[elemento.termo] !== undefined))
        if (buscaExistente) {
          buscaExistente.bool.should.push({ match: { [elemento.termo]: elemento.valor } })
        } else {
          busca.query.bool.filter[0].bool.should[0].bool.must.push({ bool: { should: [{ match: { [elemento.termo]: elemento.valor } }] } })
        }
      } else if (elemento.tipo === 'range') {
        if ((typeof elemento.valor.min === 'string')) {
          elemento.valor.min = elemento.valor.min.replace('R$', '').replace('.', '')
          elemento.valor.min = parseInt(elemento.valor.min)
        }

        if ((typeof elemento.valor.max === 'string')) {
          elemento.valor.max = elemento.valor.max.replace('R$', '').replace('.', '')
          elemento.valor.max = parseInt(elemento.valor.max)
        }

        busca.query.bool.filter[0].bool.should[0].bool.must.push({ range: { [elemento.termo]: { gte: elemento.valor.min, lte: elemento.valor.max } } })
      } else if (elemento.tipo === 'exata') {
        busca.query.bool.filter[0].bool.should[0].bool.must.push({ match: { [elemento.termo]: elemento.valor } })
      } else if (elemento.tipo === 'geral') {
        let elementos = []

        if ((elemento.valor.indexOf('Lote ') > -1) || (elemento.valor.indexOf('lote ') > -1)) {
          elementos.push(elemento.valor)
        } else {
          elementos = elemento.valor.split(' ')
        }

        elementos.forEach((elemento) => {
          busca.query.bool.filter[0].bool.should.push({
            bool: {
              should: [{
                wildcard: {
                  'marca_busca.keyword': {
                    value: '*' + elemento.toUpperCase() + '*'
                  }
                }
              },
              {
                wildcard: {
                  'modelo_busca.keyword': {
                    value: '*' + elemento.toUpperCase() + '*'
                  }
                }
              },
              {
                wildcard: {
                  'versao_busca.keyword': {
                    value: '*' + elemento.toUpperCase() + '*'
                  }
                }
              },
              {
                wildcard: {
                  'cor_objeto.cor_nome.keyword': {
                    value: '*' + elemento.toUpperCase() + '*'
                  }
                }
              },
              {
                fuzzy: {
                  'marca_objeto.marca_nome.keyword': {
                    value: elemento,
                    max_expansions: 2,
                    transpositions: true
                  }
                }
              },
              {
                fuzzy: {
                  'modelo_objeto.modelo_nome.keyword': {
                    value: elemento,
                    max_expansions: 2,
                    transpositions: true
                  }
                }
              },
              {
                fuzzy: {
                  'versao_objeto.versao_nome.keyword': {
                    value: elemento,
                    max_expansions: 2,
                    transpositions: true
                  }
                }
              },
              {
                fuzzy: {
                  'cor_objeto.cor_nome.keyword': {
                    value: elemento,
                    max_expansions: 2,
                    transpositions: true
                  }
                }
              },
              {
                match: { 'ano_modelo_busca.keyword': elemento }
              }
              ]
            }
          }
          )
        })
      }
    })

    if (busca.query.bool.filter[0].bool.should[0].bool.must.length === 0) {
      busca.query.bool.filter[0].bool.should = busca.query.bool.filter[0].bool.should.slice(1)
    }

    return busca
  }
  async buscarLoteUnico (LEL_ID) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.API['elastic'] + '/anuncios/_search?q=lel_id:' + LEL_ID).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async buscarAnuncio (id) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.API['elastic'] + '/anuncios/_search?q=id:' + id).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async buscarAnuncioByAdsetId (id) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.API['elastic'] + '/anuncios/_search?q=adset_id:' + id).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async buscarLeilao ({ LEI_ID, store } = {}) {
    return new Promise((resolve, reject) => {
      // console.warn('LEI_ID', LEI_ID)
      axios.get(process.env.API['elastic'] + '/anuncios/_search?sort=dados.LEL_ORDEM_LEILAO:asc&from=0&size=10000&q=dados.LEI_ID:' + LEI_ID).then((response) => {
        if (store) {
          store.commit('cache/putBuscasRealizadas', { responseData: response.data, index: 0, uri: '/' })
        }

        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async buscar (requisicoesBusca, store, pagina = 1, quantidadePorPagina = 14) {
    const busca = this.gerarBusca(requisicoesBusca, pagina, quantidadePorPagina)
    // let uriAtual = window.location.pathname + window.location.search
    // let indexEncontrado = null
    // let buscaState = store.state.cache.buscasRealizadas.filter((obj, index) => {
    //   if (obj.uri === uriAtual) {
    //     indexEncontrado = index
    //     return obj
    //   }
    // })

    return new Promise((resolve, reject) => {
      // retorna imediatamente
      // if (buscaState.length > 0) {
      //   // this.retornoBuscar(buscaState[0].responseData)
      //   resolve(buscaState[0].responseData)
      // }
      // console.log(process.env)
      axios.post(process.env.API['elastic'] + '/anuncios/_search', busca).then((response) => {
        // store.commit('cache/putBuscasRealizadas', { responseData: response.data, index: indexEncontrado, uri: uriAtual })

        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async possiveis () {
    return new Promise((resolve, reject) => {
      // console.log('buscaPossiveis', this.buscaPossiveis)
      axios.post(process.env.API['elastic'] + '/anuncios/_search', this.buscaPossiveis).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
