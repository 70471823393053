import { render, staticRenderFns } from "./UploadMultiplo.vue?vue&type=template&id=27f19336&"
import script from "./UploadMultiplo.js?vue&type=script&lang=js&"
export * from "./UploadMultiplo.js?vue&type=script&lang=js&"
import style0 from "./UploadMultiplo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QColor from 'quasar/src/components/color/QColor.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QAvatar,QCardActions,QBtn,QIcon,QImg,QTooltip,QSkeleton,QCircularProgress,QColor});qInstall(component, 'directives', {ClosePopup});
