import PadraoModel from 'src/core/model/PadraoModel.js'

export default class SituacaoLaudoModel extends PadraoModel {
  recurso = 'situacoes-laudos';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Situações de Laudo';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, descending: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'ativo', label: 'Ativo', field: 'ativo', sortable: true, align: 'left', buscaTipo: 'boleanoSim' },
    { name: 'prioridade', label: 'Prioridade', field: 'prioridade', sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'created_at', label: 'Criado em', field: 'created_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' },
    { name: 'updated_at', label: 'Atualizado em', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ]

  form = {
    id: null,
    nome: '',
    foto: null,
    foto_url_completa: null,
    prioridade: 1,
    ativo: 1
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
