import PadraoModel from 'src/core/model/PadraoModel.js'

export default class BitrixDealCarecaModel extends PadraoModel {
  recurso = 'bitrix24/deal-careca';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'Criar um deal careca no bitrix24';

  colunasGrid = []

  form = {
    LEAD: {
      NAME: null,
      SECOND_NAME: null,
      LAST_NAME: null,
      UF_CRM_1602009023: null,
      EMAIL: [{
        VALUE: null,
        VALUE_TYPE: 'PESSOAL'
      }],
      PHONE: [{
        VALUE: null,
        VALUE_TYPE: 'PESSOAL'
      }]
    },
    DEAL: {
      OPPORTUNITY: '',
      UF_CRM_1651977119: '', // Página
      UF_CRM_1602179548: 1,
      UF_CRM_1600859637: null,
      UF_CRM_1600859649: 'Avaliação Veiculo',
      UF_CRM_1600913053: null,
      UF_CRM_1600913067: null,
      UF_CRM_1600913084: null,
      UF_CRM_1600913106: null,
      UF_CRM_1600913147: null
    },
    bitrix_lead_id: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
