import PadraoModel from 'src/core/model/PadraoModel.js'

export default class WebmotorsModeloModel extends PadraoModel {
  recurso = 'webmotors/modelos';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Modelos Webmotors';

  colunasGrid = [
  ];

  form = {
    CodigoModelo: null,
    NomeModelo: null
  };

  constructor (marca) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (marca) this.recurso += `/${marca}`
  }
}
