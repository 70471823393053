import PadraoModel from 'src/core/model/PadraoModel.js'

export default class BiitrixModel extends PadraoModel {
  recurso = 'bitrix24/lead';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'bitrix24';

  colunasGrid = []

  form = {
    LEAD: {
      NAME: null,
      SECOND_NAME: null,
      LAST_NAME: null,
      UF_CRM_1602009023: null,
      EMAIL: [{
        VALUE: null,
        VALUE_TYPE: 'PESSOAL'
      }],
      PHONE: [{
        VALUE: null,
        VALUE_TYPE: 'PESSOAL'
      }]
    },
    DEAL: {
      OPPORTUNITY: '',
      UF_CRM_1651977119: '', // Página
      UF_CRM_1600859514: 113, // Forma de pagamento: 111 - A vista / 113 - Financiado /  115 - A vista + carro na troca /  117 - Financiado + carro na troca /  119 - Financiado + carro na troca + entrada /  807 - Financiamento externo /  839 - Avista + Cartão de Credito /  849 - Consórcio /  851 - Financiamento + Entrada / 2361 - Criptomoeda
      UF_CRM_1614713207164: null, /* Mensagem */
      UF_CRM_1604085370196: null, // Mensagem
      UF_CRM_1600860123: null, // Ano fabricação
      UF_CRM_1687972601: null, // Nome do Estoque/Local
      UF_CRM_1600913222: null, // Banco
      UF_CRM_1600866673352: null, // Cambio
      UF_CRM_1600866627564: null,
      UF_CRM_1600866641722: null,
      UF_CRM_1600912767: null,
      UF_CRM_1600913257: null,
      UF_CRM_1600912795: null,
      UF_CRM_1600913200: null,
      UF_CRM_1600861473041: null,
      UF_CRM_1600860088: null,
      UF_CRM_1600860229: null,
      UF_CRM_1601578991: null,
      UF_CRM_1600860141: null,
      UF_CRM_1600912756: null,
      UF_CRM_1600912782: null,
      UF_CRM_1600860032: null,
      UF_CRM_1600912818: null,
      UF_CRM_1601928279578: [], /* Fotos Veiculo */
      UF_CRM_1600913017: 50 /* New */
    },
    anuncio_id: null
  };

  constructor (logado) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (logado) {
      this.recurso += `/usuario/${logado.id}`
    }
  }
}
