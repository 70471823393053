import PadraoModel from 'src/core/model/PadraoModel.js'

export default class DominioCredereModel extends PadraoModel {
  recurso = 'credere/dominios';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'Domínios da Credere';

  colunasGrid = [
  ];

  form = {
    id: null,
    nome: null,
    ativo: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
