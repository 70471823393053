import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import DpGridStore from 'src/store/dp-grid/'
import { clone } from 'quasar'

export default {
  name: 'DpForm2',
  // components: { Carregamento },
  mixins: [NotificacaoMixin],
  props: ['modelUtilizadoForm', 'ocultarBotoesPadrao', 'id', 'ocultarBtnVoltar', 'ocultarBotoesETitulo', 'injectClass', 'irParaListaTratado', 'tituloCustom', 'interno'],
  data () {
    return {
      confirmar: false, // dialog confirmacao
      callback: null, // dialog confirmacao
      resposta: null, // dialog confirmacao
      msg: 'Deseja realmente concluir essa ação ?',
      situacao: 'aguardando', // buscando, aguardando, salvando, salvo, erro
      buscando: false,
      validado: false
    }
  },
  computed: {
    existeAcaoSubmitFormListener: {
      get () {
        return this.$listeners && this.$listeners.acaoSubmitForm
      }
    },
    getFormComputed: {
      get () {
        return this.$store.getters['dp-grid/getForm']
      }
    }

  },
  watch: {
    '$route.params.id' (value) {
      if (!this.interno) {
        this.id = value
        this.getId(value)
        if (typeof (this.trataForm) !== 'undefined') this.trataForm()
      }
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },

  mounted () {
    this.getId(this.id)
  },
  methods: {
    getId (id) {
      if (!isNaN(id) && id !== null) {
        if (this.$route.params.form === undefined) {
          this.buscando = true
          const notify = this.notificacao('enviando', 'Aguarde estamos buscando a informação')

          this.modelUtilizadoForm.getId({ id }).then((retorno) => {
            this.buscando = false
            notify()
            this.$emit('trataForm')
          }).catch((error) => {
            this.buscando = false
            notify()
            this.notificacao('erro', error.msg)
            console.error(error)
          })
        } else {
          this.modelUtilizadoForm.form = clone(this.$route.params.form)
          this.$emit('trataForm')
        }
      } else this.resetarForm()
    },
    clickApagarFormInterno () {
      const notificao = this.notificacao('enviando', 'Apagando registro...')
      // const modelPadrao = new PadraoModel(this.$store.getters[this.storePadrao + '/getConfiguracoesModel'])
      this.modelUtilizadoForm.deletar(this.modelUtilizadoForm.form.id).then((retorno) => {
        notificao()
        this.resetarForm()
        this.$emit('deletadoForm', this.modelUtilizadoForm.form.id)
        // atualizar rapido no grid
        const novoGrid = clone(this.$store.getters['dp-grid/getDados'][this.modelUtilizadoForm.recurso])
        if (novoGrid !== undefined) {
          const filtrado = novoGrid.filter((item) => {
            if (item.id !== this.modelUtilizadoForm.form.id) { return true }
          })
          this.$store.commit('dp-grid/putDados', { recurso: this.modelUtilizadoForm.recurso, payload: filtrado })
        }
        this.notificacao('salvo', 'Registro apagado com sucesso!', 2000)
        this.irParaListaInterno()
      }).catch(error => {
        notificao()
        this.notificacao('erro', error.msg)
        console.error(error)
      })
    },
    irParaListaInterno () {
      if (!this.ocultarBtnVoltar) {
        this.$router.push({ name: this.modelUtilizadoForm.recurso + '.grid' })
      }
    },
    controlarConfirmacao (callback, msg) {
      this.confirmar = true
      this.msg = msg
      this.callback = callback
    },
    resetarForm () {
      this.$nextTick(() => {
        this.modelUtilizadoForm.form = clone(this.modelUtilizadoForm.formLimpo)
        if (this.$refs.qDpForm !== undefined) this.$refs.qDpForm.reset()
      })
    },
    enviarFormularioInterno (naofechar = false) {
      if (this.existeAcaoSubmitFormListener !== undefined) {
        this.$emit('acaoSubmitForm')
      } else {
        this.situacao = 'salvando'
        const aviso = this.notificacao('enviando')
        const send = clone(this.modelUtilizadoForm.form)
        this.modelUtilizadoForm.salvar(send).then((response) => {
          // atualizar rapido no grid
          const novoGrid = clone(this.$store.getters['dp-grid/getDados'][this.modelUtilizadoForm.recurso])
          if (novoGrid !== undefined) {
            const filtrado = novoGrid.map((item) => {
              if (item.id === this.modelUtilizadoForm.form.id) { return this.modelUtilizadoForm.form } else { return item }
            })
            this.$store.commit('dp-grid/putDados', { recurso: this.modelUtilizadoForm.recurso, payload: filtrado })
          }
          aviso()
          this.situacao = 'salvo'
          this.notificacao('salvo', 'Salvo com sucesso', 3000)
          this.irParaListaInterno()
          this.resetarForm()
        }).catch(error => {
          aviso()
          this.situacao = 'erro'
          this.notificacao('erro', error.msg)
          console.error(error)
        })
      }
    }
  }
}
