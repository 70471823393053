export default {
  data () {
    return {
      dadosPadrao: {
        // LEL_ORDEM_LEILAO: null,
        // VEI_MOD_COMPLEMENTO: null,
        // LLF_ID: null,
        // LEL_ID: null,
        // VEI_ANOMOD: null,
        // VEI_COMBUSTIVEL: null,
        // VEI_ANOFAB: null,
        // VEI_KM: null,
        // LEL_ARREMATE_VALOR: null,
        // VEI_RETOMADA_TIPO: null,
        // LEL_VIDEO_YOUTUBE: null,
        // LEL_EMPRESA_PRECO: null,
        // PATIO_NOME: null, // adicionar aqui, e fazer o De Para no Laravel
        // LEL_DATA_ALTERACAO: '2020-09-06T20:44:00.000000Z',
        // LEL_SITUACAO: null,
        // LEL_DADOS_BUSCA: { marca: null, modelo: null, ano: null, cidade: null }
      }
    }
  },
  computed: {
    routeVeiculo (lote) {
      return {
        'marca': this.loteComp.VMM_MARCA ? this.$urlTratada(this.loteComp.VMM_MARCA) : 'imóvel',
        'modelo': this.$urlTratada(this.loteComp.VEI_MOD_COMPLEMENTO),
        'ano': this.loteComp.VEI_ANOMOD ?? 'oportunidade',
        'cidade': this.leilao_cidade ? this.$urlTratada(this.leilao_cidade) : 'BRASIL',
        'LEL_ID': this.loteComp.LEL_ID,
        'dados': this.loteComp
      }
    },
    // valorVeiculo () {
    //   return (this.loteComp.ULTIMO_VALOR && this.loteComp.ULTIMO_VALOR > 0) ?? this.loteComp.LEL_ARREMATE_VALOR ?? this.loteComp.LEL_EMPRESA_PRECO
    // },

    // valorComissao () {
    //   return this.valorVeiculo * 0.05
    // },
    // totalApagar () {
    //   return this.valorVeiculo + this.valorComissao + (this.loteComp.LEL_DESPACHANTE_PRECO ?? 0) +
    //   (this.loteComp.LEL_VALOR_REMOCAO_ACERTO ?? 0) + (this.loteComp.LEL_VALOR_VISTORIA_ACERTO ?? 0) +
    //   (this.loteComp.CMT_VALOR ?? 0) + (this.loteComp.LEL_VALOR_OUTROS_ACERTO ?? 0)
    // },
    // patioNome () {
    //   return 'PATIO'
    //   // return this.lote.veiculo?.estadia?.length > 0 ? this.lote.veiculo?.estadia[0]?.patio?.globals?.GLO_NOME : ''
    // },
    fotoMini () {
      return this.dados.bem_arquivos && this.dados.bem_arquivos[0] ? process.env.arquivosUrl + this.dados.bem_arquivos[0] : 'https://netcarros.nyc3.cdn.digitaloceanspaces.com/netcarros/9OGF4WSsQruRgcBv-1604928586.png'
    },
    foto () {
      return this.loteComp.LLF_ID ? process.env.arquivosUrl + this.lote + '/' + this.loteComp.LLF_ID + '.jpeg' : ''
    },
    logoComitente () {
      return this.loteComp.LLF_ID ? 'https://fotos12.leilomaster.com.br/comitente/' + '/' + this.loteComp.COM_ID + '.jpg' : ''
    }
    // ,
    // anoVeiculo () {
    //   return this.loteComp.VEI_ANOFAB && this.loteComp.VEI_ANOMOD ? this.loteComp.VEI_ANOFAB.toString().substr(2) + '/' + this.loteComp.VEI_ANOMOD.toString().substr(2) : ''
    //   // return this.loteComp.VEI_ANOFAB ? this.loteComp.VEI_ANOFAB + '/' + this.loteComp.VEI_ANOMOD : ''
    // },
    // kmVeiculo () {
    //   // return this.loteComp.VEI_KM ? parseInt(this.loteComp.VEI_KM.toString().replace(',', '')) : ''
    //   return this.loteComp.VEI_KM ? this.loteComp.VEI_KM.toLocaleString('de-DE', { minimumIntegerDigits: 3 }) : ''
    // },
    // retomadaVeiculo () {
    //   // let retorno
    //   // switch (this.loteComp.VEI_RETOMADA_TIPO) {
    //   //   case 'PerdaTotal':
    //   //     retorno = 'Perda Total'
    //   //     break
    //   //   case 'Amigavel':
    //   //     retorno = 'Amigável'
    //   //     break
    //   //   case 'NaoInformada':
    //   //     retorno = 'Não Informada'
    //   //     break
    //   //   case 'Judicial':
    //   //     retorno = 'Judicial'
    //   //     break
    //   // }
    //   return this.loteComp.VEI_RETOMADA_TIPO
    // }
  },
  methods: {
    linkVeiculo (lote) {
      return {
        'marca': lote.VMM_MARCA ? this.$urlTratada(lote.VMM_MARCA) : 'imóvel',
        'modelo': this.$urlTratada(lote.VEI_MOD_COMPLEMENTO),
        'ano': lote.VEI_ANOMOD ?? 'oportunidade',
        'cidade': lote.LCP_NOME ? this.$urlTratada(lote.LCP_NOME) : 'BRASIL',
        'LEL_ID': lote.LEL_ID,
        'dados': lote
      }
    },
    goto (LEL_ID) {
      this.$router.push({ name: 'item', params: { id: LEL_ID } })
    }
  }
}
