export default {
  name: 'QRadioCustom',
  props: ['value', 'val', 'label', 'disable'],
  methods: {
    emitirValor () {
      return this.$emit('input', this.val)
    }
  },
  computed: {
    ativo () {
      if (this.value === this.val) {
        return true
      } else {
      }
    }
  }
}
