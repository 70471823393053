import PadraoModel from 'src/core/model/PadraoModel.js'

export default class ConteudoModel extends PadraoModel {
  recurso = 'conteudos'
  microServico = 'usuario'
  include = null
  searchFixo = null
  nome = 'Conteúdo'

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, descending: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'barra_titulo', label: 'Barra de Título', field: 'barra_titulo', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'tipo', label: 'Tipo', field: 'tipo', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'chave_interna', label: 'Chave Interna', field: 'chave_interna', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'ativo', label: 'Ativo', field: 'ativo', sortable: true, align: 'left', buscaTipo: 'boleanoSim' },
    { name: 'prioridade', label: 'Prioridade', field: 'prioridade', descending: true, sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ]

  form = {
    id: null,
    tipo: null,
    chave_interna: null,
    url: null,
    conteudo: '',
    parametros_aceitos: null,
    obrigatorio: null,
    ativo: 1,
    barra_titulo: null,
    frase_recursos_multiplos: null,
    subtitulo: null,
    descricao_seo: null,
    javascript_externos: null,
    keywords_seo: null,
    parent_id: null,
    prioridade: 1,
    principal: null,
    imagem_principal: null
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
