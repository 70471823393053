import state from './state'
import * as actions from 'src/core/components/store/actionsDp'
import * as mutations from 'src/core/components/store/mutationsDp'
import * as getters from 'src/core/components/store/gettersDp'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
