import MixinCadastro from 'src/pages/Cadastro/MixinCadastro'

export default {
  name: 'Cadastro',
  mixins: [MixinCadastro],
  props: {
    column: String,
    hidden: false,
    titleLogin: false
  },
  mounted () {
    if (this.passosCredere) {
      this.notificacao('aviso', 'Para Continuarmos você precisa se cadastrar no sistema!')
    }

    if (this.logado) {
      this.salvando = true
      this.cadastrando = true
      this.notificacao('aviso', 'Você já esta logado!', 5000)
      setTimeout(() => {
        if (this.passosCredere) {
          this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id, publico: this.passosCredere.publico } })
        } else {
          this.irRota({ name: 'painel-inicio' })
        }
      }, 4000)
    }

    this.cadastrarRedeSocial(this.$route.params.dados)
  },
  methods: {
    validarSenha (senha = '') {
      if (!senha) senha = ''
      return senha.length < 1 || senha.length > 5
    }
  }
}
