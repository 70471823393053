import PadraoModel from 'src/core/model/PadraoModel.js'

export default class VersaoModel extends PadraoModel {
  recurso = 'versoes';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Versões';

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'marca', label: 'Marca', field: 'modelo.marca.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'modelos:modelo_id.marcas:marca_id|marcas.nome' },
    { name: 'modelo', label: 'Modelo', field: 'modelo.nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto', orderByCustomizado: 'modelos:modelo_id|modelos.nome' },
    { name: 'nome', label: 'Versão', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'ativo', label: 'Ativo', field: 'ativo', sortable: true, align: 'left', buscaTipo: 'boleanoSim' },
    { name: 'aprovado', label: 'Aprovado', field: 'aprovado', sortable: true, align: 'left', buscaTipo: 'boleano' },
    { name: 'updated_at', label: 'Atualizado', field: 'updated_at', sortable: true, align: 'left', buscaTipo: 'dataHoraMinuto' }
  ];

  form = {
    id: null,
    nome: null,
    uri: null,
    foto: null,
    foto_url_completa: null,
    prioridade: 1,
    ativo: 0,
    aprovado: 0,
    ano_inicio: '',
    ano_final: '',
    carroceria_id: null,
    modelo_id: '',
    codigo_icarros: null,
    codigo_webmotors: null,
    codigo_usadosbr: null,
    codigo_olx: null,

    /* Campos Genericos (somente auxiliar) */
    categoria_id: null,
    marca_id: null,
    modelo: {
      marca: {
        id: null,
        categoria_id: null
      }
    }
  };

  constructor (acessorios) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (acessorios) {
      this.include = 'acessorios_relacoes.acessorio'
    }
  }
}
