export default {
  name: 'Stepper',
  props: {
    steps: {
      type: Array,
      default () { return [] },
      validator (val) {
        return val && val.length > 0
      }
    },
    currentStep: {
      type: Number,
      default: 0
    }
  }
}
