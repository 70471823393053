export default {
  name: 'VeiculoResultado',
  props: {
    dados: Object,
    valorVeiculoComp: {
      type: Number,
      default: 0.00
    },
    valorExtra: {
      type: Number,
      default: 0.00
    },
    valorEntradaVeiculoComp: {
      type: Number,
      default: 0.00
    },
    valorEntradaComp: {
      type: Number,
      default: 0.00
    },
    valorMinimoFinanciamento: {
      type: Number,
      default: 0.00
    },
    possuiCarroEntrada: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    // console.log('resultado')
  }
}
