import axios from 'axios'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import draggable from 'vuedraggable'
import { clone } from 'quasar'
import CardArquivo from 'src/components/Card/CardArquivo.vue'

export default {
  name: 'UploadMultiplo',
  mixins: [NotificacaoMixin],
  components: { draggable, CardArquivo },
  data () {
    return {
      confirmarExclusao: false,
      qtdEnviada: 1,
      qtdTotal: 0,
      idExclusao: null,
      file: [],
      previa: [],
      imgAtual: null,
      enviando: false,
      sucesso: false,
      erro: false,
      validacao: false,
      carregando: false,
      resultado: null
    }
  },
  watch: {
    arquivos: {
      handler (agora) {
        if (agora) {
          this.removerImagem()
          this.$nextTick(() => {
            this.file = Object.assign([], agora)
            this.criarPrevia(true)
          })
        }
      }
    }
  },
  props: {
    dados: Object,
    todos: {
      type: Boolean,
      default: false
    },
    tipoArquivo: {
      type: String,
      default: '1'
    },
    arquivos: {
      type: Array,
      default: () => ['']
    },
    microServico: {
      type: String,
      default: 'usuario'
    },
    path: {
      type: String,
      default: '/usuario/upload-arquivos'
    }
  },
  methods: {
    toggleAcaoImagem (index) {
      this.$q.notify({
        message: '[FOTO 360] - Escolha o tipo que melhor corresponde à esta foto',
        color: 'primary',
        avatar: 'https://netcarros.nyc3.cdn.digitaloceanspaces.com/uploads-app/0ZSORGYKCzxwtYYi-1603447399_original.png',
        actions: [
          { label: 'Foto 360 interna', color: 'white', handler: () => { /* ... */ } },
          { label: 'Foto 360 externa', color: 'white', handler: () => { /* ... */ } }
        ]
      })
    },
    excluirArquivo (e) {
      if (e.id) {
        this.abrirConfirmarExclusao(e.id)
      }
    },
    alterarOrdem () {
      let arquivos = clone(this.previa).map(f => f.id)
      this.$emit('atualizar', { arquivos })
    },
    abrirConfirmarExclusao (id) {
      this.confirmarExclusao = true
      this.idExclusao = id
    },
    removerImagemAtual () {
      const id = this.idExclusao
      this.$emit('remove-arquivo', { id, tipo: this.tipoArquivo })
    },
    processarImagem (e) {
      this.validacao = false
      const file = e.target.files
      if (file) {
        if (this.todos) {
          this.file = file
          this.criarPrevia()
        } else {
          const arquivos = []
          file.forEach(f => {
            if (
              f.size > 102400000 ||
              (f.type !== 'image/png' &&
                f.type !== 'image/jpg' &&
                f.type !== 'image/jpeg' &&
                f.type !== '')
            ) {
              this.validacao = true
              this.removerImagem()
              this.notificacao('erro', 'Imagem inválida')
            } else {
              arquivos.push(f)
            }
          })
          this.file = arquivos
          this.criarPrevia()
        }
      }
    },
    removerImagem () {
      this.file = []
      this.previa = []
    },
    criarPrevia (naoEnviar = false) {
      if (this.todos) {
        let arquivos = Array.from(this.file).reduce((acc, f) => {
          let recebendo = f.arquivo_url ?? false
          let obj = {}
          obj.id = f.id ?? null
          obj.src = recebendo
            ? f.arquivo_url_completa
            : window.URL.createObjectURL(f)
          obj.carregando = !recebendo
          obj.sucesso = recebendo || false
          obj.erro = ''
          obj.enviar = !recebendo
          obj.data = f

          acc = acc.concat(obj)
          return acc
        }, [])
        this.previa = Object.assign([], arquivos)
      } else {
        let imagens = Array.from(this.file).reduce((acc, f) => {
          let recebendo = f.arquivo_url ?? false
          let obj = {}
          obj.id = f.id ?? null
          obj.src = recebendo
            ? f.arquivo_url_completa
            : window.URL.createObjectURL(f)
          obj.carregando = !recebendo
          obj.sucesso = recebendo || false
          obj.erro = ''
          obj.enviar = !recebendo
          obj.data = f

          acc = acc.concat(obj)
          return acc
        }, [])
        if (this.previa.length > 0) { imagens.map(e => this.previa.push(e)) } else this.previa = imagens
      }
      if (!naoEnviar) {
        this.enviarImagem(this.previa)
      }
    },
    criarImagemAtual (url) {
      if (url !== null) {
        this.previa = null
        this.imgAtual = url
      }
    },
    async enviarImagem (foto) {
      this.enviando = true
      const url = process.env.API[this.microServico] + this.path
      // let promises = []
      let arquivos = []
      this.erro = false
      // foto.map(async (f) => {
      let qtd = 0
      this.qtdTotal = this.previa.reduce((qtd, e) => (e.enviar ? qtd + 1 : qtd), 0)
      this.qtdEnviada = 1
      let not = null
      for (const f of foto) {
        qtd++
        if (f.enviar) {
          let arquivo = new FormData()
          arquivo.append('arquivo', f.data, f.name)
          arquivo.append('arquivo_filesize', f.data.size)
          arquivo.append('arquivo_filename', f.data.name)
          arquivo.append('tipo_arquivo_id', this.tipoArquivo)
          if (not) not()
          if (this.qtdEnviada <= this.qtdTotal) {
            not = this.notificacao('enviando', `Enviando ${this.qtdEnviada} fotos de ${this.qtdTotal}`, 0)
          }
          await axios.post(url, arquivo).then((retPost) => {
            this.qtdEnviada++
            if (retPost.data.dados) {
              arquivos = arquivos.concat(retPost.data.dados)
              this.previa.map((previa, index) => {
                if (previa.data.name === retPost.data.dados.arquivo_filename && previa.carregando === true) {
                  this.previa[index].carregando = false
                  this.previa[index].sucesso = true
                  this.previa[index].enviar = false
                  not()
                  if (this.qtdEnviada <= this.qtdTotal) {
                    not = this.notificacao('enviando', `Enviando ${this.qtdEnviada} fotos de ${this.qtdTotal}`, 0)
                  }
                }
              })
            } else {
              // this.previa[index].carregando = false
              // this.previa[index].sucesso = true
              // this.previa[index].enviar = false
            }
          })
          if (foto.length === (qtd)) {
            this.$emit('arquivos', { arquivos })
            this.enviando = false
          }
        }
      }
      // })

      // promises = foto.reduce((acc, f) => {
      //   console.warn('arquivo: ', f)
      //   if (f.enviar) {
      //     let arquivo = new FormData()
      //     arquivo.append('arquivo', f.data, f.name)
      //     arquivo.append('arquivo_filesize', f.data.size)
      //     arquivo.append('arquivo_filename', f.data.name)
      //     arquivo.append('tipo_arquivo_id', this.todos ? 6 : 1)

      //     acc = acc.concat(axios.post(url, arquivo).then((e) => { console.log('finalizei envio', e) }))
      //   }
      //   return acc
      // }, [])
      // Promise.all(promises).then(response => {
      //   const estadoAtual = clone(this.previa)
      //   response.forEach((r, index) => {
      //     arquivos = arquivos.concat(r.data.dados)
      //     if (r.data.erro === 1) {
      //       estadoAtual[index].erro = r.data.dados
      //     }
      //     estadoAtual[index].carregando = false
      //     estadoAtual[index].sucesso = true
      //     estadoAtual[index].enviar = false
      //   })
      //   this.previa = Object.assign([], estadoAtual)
      //   console.warn('entrei aqui: ', arquivos.length, promises.length)
      //   if (arquivos.length === promises.length) {
      //     this.$emit('arquivos', { arquivos })
      //     this.enviando = false
      //   }
      // })
    },
    abrirLink () {
      window.open(this.dados.imagem, '_blank')
    },
    dragStart (e) {
      e.dataTransfer.setData('text', 'data')
      e.dataTransfer.effectAllowed = 'move'
    },
    dragOver (e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'move'
    },
    drop (e) {
      e.preventDefault()
      e.stopPropagation()
      this.file = e.dataTransfer.files
      this.criarPrevia()
    }
  },
  mounted () {
    const ref = this.$refs.upload
    if (this.arquivos.length > 0) {
      this.removerImagem()
      this.$nextTick(() => {
        this.file = Object.assign([], this.arquivos)
        this.criarPrevia(true)
      })
    }
    ref.addEventListener('dragstart', this.dragStart, false)
    ref.addEventListener('dragover', this.dragOver, false)
    ref.addEventListener('drop', this.drop, false)
  }
}
