import PadraoModel from 'src/core/model/PadraoModel.js'

export default class VerificacaoModel extends PadraoModel {
  recurso = 'verificacoes';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Verificações';

  colunasGrid = [
    {
      name: 'acoes',
      label: 'Ações',
      field: 'acoes',
      sortable: false,
      style: 'width: 50px',
      align: 'left'
    },
    {
      name: 'id',
      label: 'Cód',
      field: 'id',
      sortable: true,
      descending: true,
      align: 'left',
      buscaTipo: 'inteiro'
    },
    { name: 'grupo',
      label: 'Grupo',
      field: 'grupo_verificacao.nome',
      descending: true,
      sortable: true,
      align: 'left',
      buscaTipo: 'texto',
      orderByCustomizado: 'grupo_verificacoes:grupo_verificacao_id|grupo_verificacoes.nome' },
    {
      name: 'nome',
      label: 'Nome',
      field: 'nome',
      descending: true,
      sortable: true,
      align: 'left',
      buscaTipo: 'texto'
    },
    // {
    //   name: 'uri',
    //   label: 'URI Interno',
    //   field: 'nome',
    //   descending: true,
    //   sortable: true,
    //   align: 'left',
    //   buscaTipo: 'texto'
    // },
    {
      name: 'ativo',
      label: 'Ativo',
      field: 'ativo',
      sortable: true,
      align: 'left',
      buscaTipo: 'boleanoSim'
    },
    {
      name: 'aprovado',
      label: 'Aprovado',
      field: 'aprovado',
      sortable: true,
      align: 'left',
      buscaTipo: 'boleanoSim'
    },
    {
      name: 'prioridade',
      label: 'Prioridade',
      field: 'prioridade',
      sortable: true,
      align: 'left',
      buscaTipo: 'inteiro'
    }
    // {
    //   name: 'created_at',
    //   label: 'Criado em',
    //   field: 'created_at',
    //   sortable: true,
    //   align: 'left',
    //   buscaTipo: 'dataHoraMinuto'
    // }
  ];

  form = {
    id: null,
    nome: '',
    uri: '',
    foto: null,
    foto_url_completa: null,
    prioridade: 1,
    ativo: 1,
    aprovado: 1,
    grupo_verificacao_id: null
  };

  constructor (laudo, relacao) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (laudo) {
      this.recurso = 'laudos-verificacoes'
    }
    if (relacao) {
      this.recurso = 'verificacoes-relacoes'
    }
  }
}
