import PadraoModel from 'src/core/model/PadraoModel.js'

export default class VeiculoCalcularPrecoModel extends PadraoModel {
  recurso = 'veiculo/calcular-preco';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Calcular Preço';

  colunasGrid = []

  form = {
    placa_uf: null,
    valor_fipe: null,
    marca_id: null,
    combustivel_id: null,
    ano_modelo: null,
    km: 0,

    // Aux
    placa: null,
    modelo_id: null,
    versao_id: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
