import currencyFormatter from 'currency-formatter'
import { date } from 'quasar'

// vTopFilters
export default {
  filters: {
    dateBr (value) { // ctrl + f nesse aqui
      if (!value) {
        return ''
      }
      return date.formatDate(new Date(value.replace(/ /g, 'T') + '-03:00'), 'DD/MM/YYYY HH:mm:ss')
    },
    dateMonthsCompleto (value) {
      const month = date.formatDate(value, 'M')
      const months = {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
        8: 'Agosto',
        9: 'Setembro',
        10: 'Outubro',
        11: 'Novembro',
        12: 'Dezembro'
      }
      return months[month]
    },
    dateMonths (value) {
      const month = date.formatDate(value, 'M')
      const months = {
        1: 'Jan',
        2: 'Fev',
        3: 'Mar',
        4: 'Abr',
        5: 'Mai',
        6: 'Jun',
        7: 'Jul',
        8: 'Ago',
        9: 'Set',
        10: 'Out',
        11: 'Nov',
        12: 'Dez'
      }
      return months[month]
    },
    dateWeek (value) {
      const weekDay = date.formatDate(value, 'd')
      const weekDays = {
        0: 'Dom',
        1: 'Seg',
        2: 'Ter',
        3: 'Qua',
        4: 'Qui',
        5: 'Sex',
        6: 'Sab'
      }
      return weekDays[weekDay]
    },
    dateWeekCompleto (value) {
      const weekDay = date.formatDate(value, 'd')
      const weekDays = {
        0: 'Domingo',
        1: 'Segunda',
        2: 'Terça',
        3: 'Quarta',
        4: 'Quinta',
        5: 'Sexta',
        6: 'Sábado'
      }
      return weekDays[weekDay]
    },
    optionsQDateAmanha (dates) {
      var inicio = new Date()
      inicio.setDate(inicio.getDate() + 1)
      var entrada = new Date(dates)
      return dates >= date.formatDate(inicio, 'YYYY/MM/DD') && date.getDayOfWeek(entrada) !== 7
    },
    logoComitente2 (COM_ID) {
      return COM_ID ? 'https://fotos12.leilomaster.com.br/comitente/' + '/' + COM_ID + '.jpg' : ''
    },
    dateBrSemSegundos (value) {
      return date.formatDate(value, 'DD/MM/YYYY HH:mm')
    },
    dateBrSimples (value) {
      return date.formatDate(value, 'DD/MM/YYYY')
    },
    dataReduzidaComHora (value) {
      return date.formatDate(value, 'DD/MM H:mm').replace(':', 'h')
    },
    dataReduzidaComHoraSegundos (value) {
      return value === 0 ? '-' : date.formatDate(value, 'DD/MM H:mm:ss')
    },
    horaComSeg (value) {
      return date.formatDate(value, 'HH:mm:ss')
    },
    horaH (value) {
      return date.formatDate(value, 'HH:mm').replace(':', 'h')
    },
    horaHSplit (value) {
      if (value === undefined) { return }
      const tmp = value.split(':')
      return tmp[0] + 'h' + tmp[1]
    },
    dataHumanize (datestart) {
      date.locale('pt-br')
      var format = 'YYYY/MM/DD HH-mm'
      var start = date(datestart, format)
      var end = date
      var diff = end.diff(start, 'minutes')
      return date.duration(diff, 'minutes').humanize()
    },

    dateHumanize2paramentros (datestart, dateend) {
      if (datestart === null) {
        return 'Sem Informações'
      }

      date.locale('pt-br')
      var format = 'YYYY/MM/DD HH-mm'
      var start = date(datestart, format)
      var end = date(dateend, format)
      var diff = end.diff(start, 'minutes')

      return date.duration(diff, 'minutes').humanize()
    },

    datediffDayEng (datestart) {
      var format = 'YYYY-MM-DD HH:mm:ss'
      var start = date(datestart, format)
      var end = date
      return end.diff(start, 'days')
    },

    datediffminEng (datestart) {
      var format = 'YYYY-MM-DD HH:mm:ss'
      var start = date(datestart, format)
      var end = date
      return end.diff(start, 'minutes')
    },
    datediffminEng2datas (datestart, datend) {
      var format = 'YYYY-MM-DD HH:mm:ss'
      var start = date(datestart, format)
      var end = date(datend, format)
      return end.diff(start, 'minutes')
    },
    horarioCalendario (datestart) {
      date.locale('pt-br')
      return date(datestart).calendar()
    },
    dinheiroBR (value) {
      if (isNaN(value) || value === null) {
        return ' - '
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })
      return formatter.format(value)
    },
    dinheiroBR2 (value) {
      if (isNaN(value)) {
        return ' - '
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0
      })
      return formatter.format(value)
    },
    dinheiroBRSemSifrao (value) {
      if (isNaN(value)) {
        return ' - '
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })

      const retorno = formatter.format(value)
      return retorno.replace('R$', '')
    },

    dinheiroBRSemSifrao2 (value) {
      if (isNaN(value)) {
        return ' - '
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0
      })

      const retorno = formatter.format(value)
      return retorno.replace('R$', '')
    },

    dinheiroBrToEng (value) {
      return currencyFormatter.unformat(value, { code: 'EUR' })
    },
    dinheiroBancoDeDados (value) {
      if (isNaN(value)) {
        return ' - '
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2
      })
      const retorno = formatter.format(value)
      return retorno.replace('R$', '').replace(' ', '').replace('.', '').replace(',', '.').trim()
    },

    mascaraCPF (value) {
      // eslint-disable-next-line
      return value ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4") : null
    },

    mascaraCnpj (value) {
      // eslint-disable-next-line
      return value ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5") : null
    },

    soNumeros (value) {
      return value ? value.replace(/[^\d]+/g, '') : null
    },

    minutos_para_hora (s) {
      var hora = Math.floor(s / 60)

      if (hora > 1) return `${hora} horas`
      if (hora > 0) return `${hora} hora`
      else return `${s} min (s)`
    },

    addMinutosData (date, minutes) {
      return date === null ? null : date(date).add(minutes, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    },

    addSegundosData (date, seconds) {
      return date === null ? null : date(date).add(seconds, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    },

    addDiasAgoraBr (dias) {
      return date.add(dias, 'days').format('DD/MM/YYYY')
    },

    addDiasAgoraEng (dias) {
      return date.add(dias, 'days').format('YYYY-MM-DD')
    },

    addDiasDateEngForBr (date, dias) {
      return date(date, 'YYYY-MM-DD').add(dias, 'days').format('DD/MM/YYYY')
    },

    addDiasDateEngForEng (date, dias) {
      return date(date, 'YYYY-MM-DD').add(dias, 'days').format('YYYY-MM-DD')
    },

    removeDiasAgoraEng (dias) {
      return date.subtract(dias, 'd').format('YYYY-MM-DD')
    },

    removeAnosAgoraEng (anos) {
      const timeStamp = Date.now()
      return date.formatDate(date.subtractFromDate(timeStamp, { year: anos }), 'YYYY-MM-DD')
    },

    datediffSegEng (datestart) {
      var format = 'YYYY-MM-DD HH:mm:ss'
      var start = date(datestart, format)
      var end = date
      return end.diff(start, 'seconds')
    },
    datediffSegEng2datas (datestart, datend) {
      var format = 'YYYY-MM-DD HH:mm:ss'
      var start = date(datestart, format)
      var end = date(datend, format)
      return end.diff(start, 'seconds')
    },

    dataDescritivo1 (date) {
      date.locale('pt-br')
      return date(date).format('D MMM')
    },

    dataComHoraHDescritivo (date) {
      date.locale('pt-br')
      return date(date).format('HH:mm& DD MMM').replace('&', 'h')
    },

    dataComHoraHDescritivoYYYY (date) {
      date.locale('pt-br')
      return date(date).format('HH:mm& DD MMM YYYY').replace('&', 'h')
    },
    dataDescritivoCompleto (date) {
      date.locale('pt-br')
      return date(date).format('DD & MMMM & YYYY').replace(/&/g, 'de')
    },
    dataDiaD (date) {
      return date(date).format('D')
    },

    duas_casas (numero) {
      if (numero <= 9) { numero = `0${numero}` }
      return numero
    },

    dateTimeEng (value) {
      return date.formatDate(value, 'YYYY-MM-DD HH:mm:ss')
    },

    dateEng (value) {
      return date.formatDate(value, 'YYYY-MM-DD')
    },

    dateEng2 (value) {
      if (value) {
        return date.formatDate(this.convertDateTimeForTimeStamp(value, '/', ':', 'br'), 'YYYY-MM-DD')
      } else {
        return value
      }
    },

    dateBr2 (value) {
      if (value) {
        return date.formatDate(this.convertDateTimeForTimeStamp(value), 'DD/MM/YYYY')
      } else {
        return value
      }
    },

    dateTimeBr (value) {
      if (value) {
        return date.formatDate(this.convertDateTimeForTimeStamp(value), 'DD/MM/YYYY HH:mm')
      } else {
        return value
      }
    },

    dateBrMesDescritivo (value) {
      if (value) {
        return date.formatDate(this.convertDateTimeForTimeStamp(value), 'DD MMM YYYY')
      } else {
        return value
      }
    },

    horaHoje () {
      const timeStamp = Date.now()
      return date.formatDate(timeStamp, 'HH:mm')
    },

    hora (value) {
      if (value) {
        return date.formatDate(this.convertDateTimeForTimeStamp(value), 'HH:mm')
      } else {
        return value
      }
    },

    convertDateTimeForTimeStamp (value, splitData = '-', splitHora = ':', tipo = 'us') {
      let timeStamp = null
      const data = value ? value.split(splitData) : null
      const hora = value ? value.split(splitHora) : null

      if (!hora[1]) hora[1] = '00'
      if (!hora[2]) hora[2] = '00'

      if (data && data[2] && hora && hora[2]) {
        data[2] = data[2].split(' ')[0]
        hora[0] = hora[0].split(' ')[1]

        if (!hora[0]) hora[0] = '00'
        if (!hora[1]) hora[1] = '00'
        if (!hora[2]) hora[2] = '00'

        timeStamp = new Date(data[0], data[1] - 1, data[2], hora[0], hora[1], hora[2]).getTime()
        if (tipo === 'br') {
          timeStamp = new Date(data[2], data[1] - 1, data[0], hora[0], hora[1], hora[2]).getTime()
        }
      }
      return timeStamp
    },

    hoje () {
      const timeStamp = Date.now()
      return date.formatDate(timeStamp, 'DD/MM/YYYY')
    },

    hojeEng () {
      const timeStamp = Date.now()
      return date.formatDate(timeStamp, 'YYYY-MM-DD')
    },

    hojeEngDateTime () {
      const timeStamp = Date.now()
      return this.dateTimeEng(timeStamp)
    },

    mesAnoAtual () {
      const timeStamp = Date.now()
      return date.formatDate(timeStamp, 'YYYY/MM')
    },

    anoAtual () {
      const timeStamp = Date.now()
      return date.formatDate(timeStamp, 'YYYY')
    },

    anoAtualYY () {
      const timeStamp = Date.now()
      return date.formatDate(timeStamp, 'YY')
    },

    HEX2RGB (hex) {
      'use strict'
      if (hex.charAt(0) === '#') {
        hex = hex.substr(1)
      }
      if ((hex.length < 2) || (hex.length > 6)) {
        return false
      }
      var values = hex.split(''),
        r,
        g,
        b

      if (hex.length === 2) {
        r = parseInt(values[0].toString() + values[1].toString(), 16)
        g = r
        b = r
      } else if (hex.length === 3) {
        r = parseInt(values[0].toString() + values[0].toString(), 16)
        g = parseInt(values[1].toString() + values[1].toString(), 16)
        b = parseInt(values[2].toString() + values[2].toString(), 16)
      } else if (hex.length === 6) {
        r = parseInt(values[0].toString() + values[1].toString(), 16)
        g = parseInt(values[2].toString() + values[3].toString(), 16)
        b = parseInt(values[4].toString() + values[5].toString(), 16)
      } else {
        return false
      }
      return [r, g, b].join(',')
    },

    removerAcentos (newStringComAcento) {
      var string = newStringComAcento

      var mapaAcentosHex = {
        a: /[\xE0-\xE6]/g,
        A: /[\xC0-\xC6]/g,
        e: /[\xE8-\xEB]/g,
        E: /[\xC8-\xCB]/g,
        i: /[\xEC-\xEF]/g,
        I: /[\xCC-\xCF]/g,
        o: /[\xF2-\xF6]/g,
        O: /[\xD2-\xD6]/g,
        u: /[\xF9-\xFC]/g,
        U: /[\xD9-\xDC]/g,
        c: /\xE7/g,
        C: /\xC7/g,
        n: /\xF1/g,
        N: /\xD1/g
      }

      for (var letra in mapaAcentosHex) {
        var expressaoRegular = mapaAcentosHex[letra]
        string = string.replace(expressaoRegular, letra)
      }

      return string
    },

    capitalizeFirstLetter (string = '') {
      if (string && string.length > 1) {
        var loweredText = string.toLowerCase()
        var words = loweredText.split(' ')
        for (var a = 0; a < words.length; a++) {
          var w = words[a]

          var firstLetter = w[0]
          w = firstLetter ? firstLetter.toUpperCase() + w.slice(1) : null

          words[a] = w
        }
        return words.join(' ')
      } else return ''
    },
    tratarBugDateFirebase (dataFB) {
      let mss
      // console.warn('dataFB', dataFB)
      if (!dataFB) {
        return 0
      }
      if (!dataFB._seconds) {
        mss = dataFB.seconds * 1000
      } else {
        mss = dataFB._seconds * 1000
      }
      return mss
    },
    tratarBugDateDiferencaSegundosAgora (dataFB) {
      let mss
      // console.warn('dataFB', dataFB)
      if (!dataFB) {
        return 0
      }
      if (!dataFB._seconds) {
        mss = dataFB.seconds * 1000
      } else {
        mss = dataFB._seconds * 1000
      }
      return (Date.now() - mss) / 1000
    },
    validarCNPJ (cnpj) {
      if (cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '')
        if (cnpj === '') return false
        if (cnpj.length !== 14) {
          return false
        }
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999') {
          return false
        }
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho)
        const digitos = cnpj.substring(tamanho)
        let soma = 0
        let pos = tamanho - 7
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--
          if (pos < 2) {
            pos = 9
          }
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
        if (resultado !== parseInt(digitos.charAt(0))) {
          return false
        }
        tamanho = tamanho + 1
        numeros = cnpj.substring(0, tamanho)
        soma = 0
        pos = tamanho - 7
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--
          if (pos < 2) {
            pos = 9
          }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
        if (resultado !== parseInt(digitos.charAt(1))) {
          return false
        }
        return true
      }
      return false
    },
    arquivoOriginal (arquivo) {
      return arquivo.substr(0, arquivo.lastIndexOf('.')) + '_original' + arquivo.substr(arquivo.lastIndexOf('.'))
    }
  }
}
