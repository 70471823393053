import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ModeloModel from 'src/model/veiculo/ModeloModel'
export default {
  name: 'MaisBuscados',
  components: { VueSlickCarousel },
  props: {
    dados: {
      type: Array,
      default: () => {
        return [
          {
            img: 'home/mais-buscados',
            marca: 'Fiat',
            class: 'btn-mais-buscados-fiat-argo',
            modelo: 'Argo'
          },
          {
            img: 'home/mais-buscadosmobi',
            marca: 'Fiat',
            class: 'btn-mais-buscados-fiat-mobi',
            modelo: 'Mobi'
          },
          {
            img: 'home/mais-buscadospegeot',
            marca: 'Peugeot',
            class: 'btn-mais-buscados-peugeot-208',
            modelo: '208'
          },
          {
            img: 'home/mais-buscadostoro',
            marca: 'Fiat',
            class: 'btn-mais-buscados-fiat-toro',
            modelo: 'Toro'
          },
          {
            img: 'home/mais-buscadosrenegade',
            marca: 'Jeep',
            class: 'btn-mais-buscados-jeep-renegade',
            modelo: 'Renegade'
          },
          {
            img: 'home/mais-buscadoscompass',
            marca: 'Jeep',
            class: 'btn-mais-buscados-jeep-compass',
            modelo: 'Compass'
          }
        ]
      }
    }
  },
  mounted () {
    let model = new ModeloModel()
    model.getListagem({
      params: {
        limit: 10,
        orderBy: 'acesso',
        sortedBy: 'desc'
      }
    }).then((res) => {
      if (res.dados && res.dados.length > 0) {
        let dados = res.dados
        this.dados = dados.map((item) => {
          return {
            img: item.foto_url_completa ?? 'home/mais-buscados.jpg',
            marca: item.marca.nome,
            class: 'btn-mais-buscados' + (item && item.nome ? item.nome.toLowerCase() : ''),
            modelo: item.nome
          }
        })
      }
    })
  },
  data () {
    return {
      carrosselMaisBuscados: {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 0
            }
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 1.9,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  }
}
