import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CredereSimulacaoModel extends PadraoModel {
  recurso = 'credere/simulacoes';
  microServico = 'usuario';
  include = 'request_log';
  searchFixo = null;
  nome = 'Credere Simulação';

  colunasGrid = []

  form = {
    id: null,
    deal_id: null,
    usuario_id: null,
    assets_value: null, /* Valor do Veiculo */
    seller_cpf: null,
    documentation_value: '0', /* Valor do documento do veiculo */
    insurance_value: '0', /* Valor do Seguro */
    accessory_value: '0', /* Valor do Acessorios */
    retrieve_lead: {
      cpf_cnpj: null
    },
    vehicle: {
      vehicle_molicar_code: null,
      licensing_uf: null,
      manufacture_year: null,
      model_year: null,
      asset_value: null,
      zero_km: null, /* Boolean */
      retrieve_fuel_type: null /* Combustivel */
    },
    conditions: [
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '12', /* Parcela */
        run_pre_approval: '1'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '24', /* Parcela */
        run_pre_approval: '1'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '36', /* Parcela */
        run_pre_approval: '1'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '48', /* Parcela */
        run_pre_approval: '1'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '60', /* Parcela */
        run_pre_approval: '1'
      }
    ],
    conditions1: [
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '12', /* Parcela */
        run_pre_approval: '1',
        include_financial_protection_insurance: '1',
        quota_preference: 'min',
        return_preference: 'ignore',
        min_return: '0',
        max_return: '3'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '24', /* Parcela */
        run_pre_approval: '1',
        include_financial_protection_insurance: '1',
        quota_preference: 'min',
        return_preference: 'ignore',
        min_return: '0',
        max_return: '3'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '36', /* Parcela */
        run_pre_approval: '1',
        include_financial_protection_insurance: '1',
        quota_preference: 'min',
        return_preference: 'ignore',
        min_return: '0',
        max_return: '3'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '48', /* Parcela */
        run_pre_approval: '1',
        include_financial_protection_insurance: '1',
        quota_preference: 'min',
        return_preference: 'ignore',
        min_return: '0',
        max_return: '3'
      },
      {
        down_payment: null, /* Valor Entrada */
        financed_amount: null, /* Valor total - Valor Entrada */
        installments: '60', /* Parcela */
        run_pre_approval: '1',
        include_financial_protection_insurance: '1',
        quota_preference: 'min',
        return_preference: 'ignore',
        min_return: '0',
        max_return: '3'
      }
    ],
    all_credit_conditions: '0'
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
